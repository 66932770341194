<div class="header__background">
  <mat-toolbar role="toolbar" class="task-header">
    <h1 mat-dialog-title>{{'Aufgabe erledigt?'}}</h1>
    <span class="fx-spacer"></span>
    <button
      (click)="clickCancelButton()"
      [disabled]="(confirmSpinner$ | async) === true"
      mat-icon-button>
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <p>Möchten Sie diese Aufgabe als erledigt markieren?</p>
</div>
<div class="footer" mat-dialog-actions align="end">
  <mat-spinner [diameter]="25" *ngIf="(confirmSpinner$ | async) === true"></mat-spinner>
  <button
    mat-button color="primary"
    (click)="clickCancelButton()"
    [disabled]="(confirmSpinner$ | async) === true">
    {{'Nein'}}
  </button>
  <button
    mat-flat-button color="primary"
    (click)="clickSaveButton()"
    [disabled]="(confirmSpinner$ | async) === true"
    class="button-primary">
    {{'Ja'}}
  </button>
</div>

