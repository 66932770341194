<mat-tab-group>

  <mat-tab>
    <ng-template matTabLabel>
      Allgemein
    </ng-template>
    <app-general-settings></app-general-settings>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      Mandanten
    </ng-template>
    <app-clients></app-clients>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      Benutzer
    </ng-template>
    <app-users></app-users>
    <div class="block-sync">
      <button
        mat-raised-button
        color="primary"
        class="btn-sync"
        (click)="startSync()"
        [disabled]="(loadingSync$ | async) === true"
      >Synchronisiere Mitarbeiter mit Mandanten
      </button>
      <ng-container
        *ngIf="(loadingSync$ | async) === true">
        <mat-spinner
          [diameter]="25">
        </mat-spinner>
        <p>Die Synchronisation wurde gestartet. Bitte verlassen Sie nicht diese Ansicht, bevor die Synchronisation
          abgeschlossen wurde. Ansonsten wird diese abgebrochen.
        </p>
      </ng-container>

    </div>
  </mat-tab>

  <mat-tab>
    <ng-template matTabLabel>
      Dokumente
    </ng-template>
    <app-dms-settings></app-dms-settings>
    <app-sync-mails-periods-assessments-settings></app-sync-mails-periods-assessments-settings>
  </mat-tab>
</mat-tab-group>




