import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap
} from "rxjs";

@Component({
  selector: 'app-dms-settings',
  templateUrl: './dms-settings.component.html',
  styleUrls: ['./dms-settings.component.css']
})
export class DmsSettingsComponent implements OnInit {

  dmsSettingsForm = this.fb.group({
    userId: this.fb.control('', [Validators.required]),
    domainSelect: this.fb.control('', [Validators.required]),
    domainId: this.fb.control('', [Validators.required]),
    folderId: this.fb.control('', [Validators.required]),
    registerId: this.fb.control('', [Validators.required]),
    documentInfo: this.fb.control('', [Validators.required]),
    documentStatus: this.fb.control('', []),
    domainSearch: this.fb.control('')

  });

  loadingSubmitDMSSettingsForm$ = new BehaviorSubject(false);

  allSelectableDomains: any[] = [];
  filteredSelectableDomains: Map<string, string> = new Map<string, string>();
  selectableUsers$: Observable<any>;
  selectableDomains$: Observable<any>;
  dmsInfo$: Subscription;
  documentStates$: Observable<any> | null = null;
  dmsFeatureEnabled = false;

  reload$ = new BehaviorSubject(true);

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService) {

    this.requestManager.get('dms/settings/')
      .pipe(
      ).subscribe((settings) => {
      this.dmsSettingsForm.patchValue({
        userId: settings.userId,
        domainId: settings.domainId,
        folderId: settings.folderId,
        registerId: settings.registerId,
        documentStatus: settings.documentStatus,
        domainSelect: settings.domainId + ' - ' + settings.folderId + ' - ' + settings.registerId
      })


    }, () => {
      this.notifier.notify('error', 'Es gab einen Fehler beim Laden der DMS-Einstellungen!');
    })

    this.selectableUsers$ = combineLatest([this.reload$]).pipe(
      switchMap(([reload]: any) => {

        if (reload) {
          return this.requestManager.getForInstance('dms/users/').pipe(map((users) => Object.values(users)));
        }
        return of([]);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der DMS-Benutzer! Melden Sie Ihr Problem bei support@riecken.io!');
        return of([]);
      }));


    this.selectableDomains$ = combineLatest([this.reload$]).pipe(
      switchMap(([reload]: any) => {

        if (reload) {
          return this.requestManager.getForInstance('dms/domains/').pipe(map((domains) => {
              return Object.values(domains);
            }),
            tap((domains) => {
              this.allSelectableDomains = domains;
              this.filterSelectableDomains('');
            }));
        }
        return of([]);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der DMS-Register! Melden Sie Ihr Problem bei support@riecken.io!');
        return of([]);
      }));

    this.dmsSettingsForm.get('domainSearch')?.valueChanges.pipe(
      debounceTime(300),
      tap((filterText: string) => {
        this.filterSelectableDomains(filterText);
      }))
      .subscribe();

    this.dmsInfo$ = combineLatest([this.reload$]).pipe(
      switchMap(([reload]: any) => {

        if (reload) {
          return this.requestManager.getForInstance('dms/info/').pipe(tap((infos) => {

            if (infos.feature) {
              this.dmsSettingsForm.get('documentInfo')?.patchValue(infos.feature);
              if (infos.feature === 'Dms') {
                this.dmsFeatureEnabled = true;

                this.dmsSettingsForm.get('documentStatus')?.setValidators([Validators.required]);
              } else {
                this.dmsFeatureEnabled = false;
                this.dmsSettingsForm.get('documentStatus')?.setValidators([]);
              }

              this.dmsSettingsForm.get('documentStatus')?.updateValueAndValidity();

              this.documentStates$ = this.requestManager.getForInstance('dms/states/').pipe(map((states) => {

                  return Object.values(states);
                }), catchError(() => {
                  this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Status ! Melden Sie Ihr Problem bei support@riecken.io!');
                  return of([]);
                })
              );
            }
          }));
        }
        return of([]);
      }),
      catchError(() => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der DMS-Info! Melden Sie Ihr Problem bei support@riecken.io!');
        return of([]);
      })).subscribe();

    this.dmsSettingsForm.get('domainSelect')?.valueChanges.subscribe(
      (selectValue: string) => {
        const selectValueParts = selectValue.split('-', 3);
        if (selectValueParts.length === 3) {
          this.dmsSettingsForm.get('domainId')?.patchValue(selectValueParts[0].trim());
          this.dmsSettingsForm.get('folderId')?.patchValue(selectValueParts[1].trim());
          this.dmsSettingsForm.get('registerId')?.patchValue(selectValueParts[2].trim());
        } else {
          this.notifier.notify('error', 'Es gab einen Fehler bei der Auswahl des Registers! Melden Sie Ihr Problem bei support@riecken.io!');
        }
      }, () => {
        this.notifier.notify('error', 'Es gab einen Fehler bei der Auswahl des Registers! Melden Sie Ihr Problem bei support@riecken.io!');
      });


  }

  ngOnInit(): void {
    this.reload$.next(true);
  }

  submitDmsSettingsForm() {

    this.loadingSubmitDMSSettingsForm$.next(true);
    const body = this.dmsSettingsForm.value;
    this.requestManager.put('dms/settings/', body)
      .subscribe(
        () => {
          this.loadingSubmitDMSSettingsForm$.next(false);

        },
        () => {
          this.notifier.notify('error', 'Es gab einen Fehler beim Speichern der DMS - Einstellungen! Melden Sie Ihr Problem bei support@riecken.io!');

          this.loadingSubmitDMSSettingsForm$.next(false);
        }
      )
  }

  private filterSelectableDomains(filterText: string) {
    if (filterText === '') {
      this.filteredSelectableDomains?.clear();
      for (let domain of this.allSelectableDomains) {
        if (domain.folders) {
          for (let folder of domain.folders) {
            if (folder.registers) {
              for (let register of folder.registers) {
                this.filteredSelectableDomains?.set(domain.id + ' - ' + folder.id + ' - ' + register.id, domain.name + ' - ' + folder.name + ' - ' + register.name)
              }
            }
          }
        }
      }
    } else {
      this.filteredSelectableDomains?.clear();
      /*
      prepare array, only need to be displayed
       */
      for (let domain of this.allSelectableDomains) {
        if (domain.folders) {
          for (let folder of domain.folders) {
            if (folder.registers) {
              for (let register of folder.registers) {
                if (domain.name.toLowerCase().includes(filterText.toLowerCase())
                  || folder.name.toLowerCase().includes(filterText.toLowerCase())
                  || register.name.toLowerCase().includes(filterText.toLowerCase())) {
                  this.filteredSelectableDomains?.set(domain.id + ' - ' + folder.id + ' - ' + register.id, domain.name + ' - ' + folder.name + ' - ' + register.name)
                }
              }
            }
          }
        }
      }

    }
  }


}
