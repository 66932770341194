import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../../services/request-manager.service";
import {BehaviorSubject, combineLatest, debounceTime, map, Observable, of, Subject, switchMap, take, tap} from "rxjs";
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  addedClients$: Observable<any>
  addableClients$: Observable<any>

  allAddableClients: any[] = [];
  filteredAddableClients: any[] = [];
  loadingAddClient$ = new BehaviorSubject(false);


  $reload = new BehaviorSubject(false);

  addClientForm = this.fb.group({
    clientId: this.fb.control('', [Validators.required]),
    clientsSearch: this.fb.control('')
  });

  orderClients$ = new BehaviorSubject({orderBy: 'clientNumber', order: 'DESC'});

  limit = 10;
  limitOptions = [1, 2, 5, 10, 25, 50, 100];
  offset = 0;
  nextPageDisabled = false;
  clientFilterText = '';
  clientFilterText$ = new BehaviorSubject('');

  constructor(private requestManager: RequestManagerService,
              private fb: FormBuilder) {


    this.addableClients$ = combineLatest([this.$reload]).pipe(
      switchMap(([reload]: any) => {

        if (reload) {
          return this.requestManager.getForInstance('clients/notAssigned').pipe(
            map((clients) => {
              this.allAddableClients = clients;
              this.filteredAddableClients = clients;

              return this.filteredAddableClients;
            })
          );
        }
        return of([]);
      })
    );

    this.addClientForm.get('clientsSearch')?.valueChanges.pipe(
      debounceTime(300),
      tap((filterText: string) => {
        this.filterAddableClients(filterText);
      }))
      .subscribe();

    this.addedClients$ = combineLatest([this.$reload,
      this.orderClients$,
      this.clientFilterText$.pipe(
        debounceTime(300)
      )]).pipe(
      switchMap(([reload, orderClients, filterText]: any) => {
        if (reload) {
          return this.requestManager.getForInstance('clients'
            + '?offset=' + this.offset
            + '&limit=' + this.limit
            + '&orderBy=' + orderClients.orderBy
            + '&order=' + orderClients.order
            + '&filterText=' + filterText)
            .pipe(
              map((clients) => {
                return Object.values(clients);
              })
            );
        }
        return of([]);
      }));


  }

  ngOnInit(): void {
    this.$reload.next(true);
  }

  clientFilterTextChanged() {
    this.clientFilterText$.next(this.clientFilterText);
  }

  addClient(): void {
    this.loadingAddClient$.next(true);
    const body = this.addClientForm.value;

    this.requestManager.post('clients/add', body)
      .pipe(
        take(1)
      )
      .subscribe(
        (success) => {
          console.log(success);
          this.addClientForm.reset();
          this.$reload.next(true);
          this.loadingAddClient$.next(false);
        },
        (error) => {
          console.log(error);
          this.loadingAddClient$.next(false);
        }
      )
  }

  getNextPage() {
    this.offset += this.limit;
    console.log(this.offset);
    this.$reload.next(true);
  }

  getPreviousPage() {
    this.offset -= this.limit;
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.$reload.next(true);
  }

  reloadClients() {
    this.$reload.next(true);
  }

  previousPageButtonIsDisabled() {
    return (this.offset - this.limit) < 0;
  }

  orderBy(orderBy: string) {
    const actualOrderBy = this.orderClients$.value.orderBy;
    const actualOrder = this.orderClients$.value.order;
    let newOrder = 'ASC';
    let newOrderBy = orderBy;

    if (orderBy === actualOrderBy) {
      newOrderBy = actualOrderBy;
      if (actualOrder === 'ASC') {
        newOrder = 'DESC';
      } else if (actualOrder === 'DESC') {
        newOrder = 'ASC';
      }
    }

    this.orderClients$.next({orderBy: newOrderBy, order: newOrder});
  }

  private filterAddableClients(filterText: string) {
    if (filterText === '' || filterText === null) {
      this.filteredAddableClients = this.allAddableClients;
    } else {
      this.filteredAddableClients = this.allAddableClients.filter(
        (client) => {
          return client.name.toString().toLowerCase().includes(filterText.toLowerCase())
            || client.number.toString().includes(filterText.toLowerCase());
        }
      );
    }
  }
}
