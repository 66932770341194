<div class="container-form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Login
      </mat-card-title>
    </mat-card-header>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card-content>
      <div></div>
      <div [formGroup]="loginForm" class="form">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Benutzername</mat-label>
          <input
            matInput
            formControlName="username"
            (keyup.enter)="onEnterKey()"/>
          <mat-error *ngIf="loginForm.get('username')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>


        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Passwort</mat-label>
          <input
            type="{{hide ? 'password' : 'text'}}"
            matInput
            formControlName="password"
            (keyup.enter)="onEnterKey()"/>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="loginForm.get('password')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-footer class="mat-card-footer-submit">
      <mat-spinner
        *ngIf="(loadingSubmit$ | async) === true"
        [diameter]="25">
      </mat-spinner>
      <button mat-raised-button
              class="button-primary"
              color="primary"
              [disabled]="loginForm.invalid || (loadingSubmit$ | async) === true"
              (click)="login()">Anmelden
      </button>
    </mat-card-footer>
  </mat-card>
</div>
