<mat-card>
  <mat-card-header>
    <mat-card-title>
      Individuelle Einstellungen
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="form" [formGroup]="syncSettingsForm">

      <div class="block-input-reminder-hours">
        <div class="label-input-reminder-hours">Mandanten über ungelesene Dokumente benachrichtigen, erste / zweite /
          letzte Benachrichtigung:
          <mat-icon class="mat-icon-info"
                    matTooltip="Schreibe 0 in ein Eingabefeld, wenn eine Erinnerung nicht gemacht werden soll"
                    matTooltipPosition="right"
          >info_outline
          </mat-icon>
        </div>
        <div class="block-input-reminder-hours-form-fields">
          <mat-form-field appearance="fill">
            <mat-label>Stunden</mat-label>
            <input
              matInput
              formControlName="firstDocumentReminderAfter"
              type="number"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Stunden</mat-label>
            <input
              matInput
              formControlName="secondDocumentReminderAfter"
              type="number"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Stunden</mat-label>
            <input
              matInput
              formControlName="thirdDocumentReminderAfter"
              type="number"
            />
          </mat-form-field>
        </div>

      </div>

      <div class="block-outbox-types">
        <mat-form-field appearance="fill"
                        class="margin-top-form-field form-field-select"
        >
          <mat-label>Gewählte Postausgangsart</mat-label>
          <mat-select formControlName="outboxType">
            <ng-container *ngIf="$orderTypes | async as orderTypes">
              <mat-option *ngFor="let orderType of orderTypes" [value]="orderType.number">
                {{orderType.name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div>
          <mat-spinner [diameter]="25" *ngIf="(loadingOutboxTypeToggle$ | async) === true"></mat-spinner>
        </div>
      </div>

      <div class="block-outbox-types">
        <mat-form-field appearance="fill"
                        class="margin-top-form-field form-field-select"
        >
          <mat-label>Anzeige Postausgang-Absender</mat-label>
          <mat-select formControlName="displayCommittingEmployeeAsSender">
            <mat-option [value]="'0'">
              Ersteller
            </mat-option>
            <mat-option [value]="'1'">
              Festschreiber
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <mat-spinner [diameter]="25" *ngIf="(loadingOutboxTypeToggle$ | async) === true"></mat-spinner>
        </div>
      </div>


      <mat-form-field appearance="fill" *ngIf="(documentStates$ |async) as documentStates"
                      class="margin-top-form-field form-field-select"
      >
        <mat-label>Dokumentenstatus für Mandanten-Aufgaben</mat-label>
        <mat-select formControlName="documentStatusIdForClientTasks">
          <mat-option *ngFor="let state of documentStates" value="{{state.id}}">
            {{state.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="syncSettingsForm.get('documentStatusIdForClientTasks')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="(documentStates$ |async) as documentStates"
                      class="margin-top-form-field form-field-select"
      >
        <mat-label>Dokumenten-Folgestatus für Mandanten-Aufgaben</mat-label>
        <mat-select formControlName="documentStatusIdForClientTaskApproval">
          <mat-option *ngFor="let state of documentStates" value="{{state.id}}">
            {{state.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="syncSettingsForm.get('documentStatusIdForClientTaskApproval')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>


      <mat-slide-toggle formControlName="synchroniseOutboxesActive"
                        class="slide-toggle-container block-synchroniseOutboxesActive">
        <div class="toggle-container">
          Synchronisation ist aktiviert
          <mat-icon class="mat-icon-info"
                    matTooltip="Wird dies aktiviert, werden Postausgangsätze mit den hier festgelegten Einstellungen synchronisiert"
                    matTooltipPosition="right"
          >info_outline
          </mat-icon>
        </div>
      </mat-slide-toggle>


      <div class="block-syncOutboxesFromDate"
           *ngIf="this.syncSettingsForm.get('synchroniseOutboxesActive')?.value &&
        !this.syncSettingsForm.get('lastSynchronisationOutboxes')?.value"
      >
        <div>
          Lege das Datum fest, ab welchem die Postausgangsätze für die erstmalige Synchronisation berücksichtigt werden
          sollen
        </div>
        <mat-form-field
          appearance="fill"
        >
          <mat-label>Ab</mat-label>
          <input
            matInput
            [matDatepicker]="dpFromDate"
            placeholder="dd.MM.yyyy"
            formControlName="syncOutboxesFromDate"
          />
          <mat-datepicker-toggle matSuffix [for]="dpFromDate"></mat-datepicker-toggle>
          <mat-datepicker #dpFromDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>


  </mat-card-content>
  <mat-card-footer>
    <button mat-raised-button
            color="primary"
            class="btn-save-settings"
            (click)="submitSyncSettingsForm()"
            [disabled]="!syncSettingsForm.valid|| (loadingSubmitSyncSettingsForm$ | async) === true">
      Speichern
    </button>
    <mat-spinner class="spinner-loading-add-client"
                 *ngIf="(loadingSubmitSyncSettingsForm$ | async) === true"
                 [diameter]="25">
    </mat-spinner>
  </mat-card-footer>

</mat-card>



