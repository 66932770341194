<div class="container-form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Neue Kundeninstanz erstellen
      </mat-card-title>
    </mat-card-header>
    <mat-divider [inset]="true"></mat-divider>
    <mat-card-content>
      <div></div>
      <div [formGroup]="createInstanceForm" class="form">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Administrator - Email</mat-label>
          <input
            matInput
            formControlName="adminEmail"/>
          <mat-error *ngIf="createInstanceForm.get('adminEmail')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>


        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Passwort</mat-label>
          <input
            type="password"
            matInput
            formControlName="adminPassword"/>
          <mat-error *ngIf="createInstanceForm.get('adminPassword')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Kundenname</mat-label>
          <input
            matInput
            formControlName="name"/>
          <mat-error *ngIf="createInstanceForm.get('name')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>DatevConnect Username</mat-label>
          <input
            matInput
            formControlName="datevConnectUsername"/>
          <mat-error *ngIf="createInstanceForm.get('datevConnectUsername')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>DatevConnect Passwort</mat-label>
          <input
            matInput
            formControlName="datevConnectPassword"/>
          <mat-error *ngIf="createInstanceForm.get('datevConnectPassword')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Link zum Impressum</mat-label>
          <input
            matInput
            formControlName="imprintLink"/>
          <mat-error *ngIf="createInstanceForm.get('imprintLink')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Link zur Kontaktseite</mat-label>
          <input
            matInput
            formControlName="contactLink"/>
          <mat-error *ngIf="createInstanceForm.get('contactLink')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Unternehmen - Bezeichnung</mat-label>
          <input
            matInput
            formControlName="companyName"/>
          <mat-error *ngIf="createInstanceForm.get('companyName')?.hasError('required')">
            Muss ausgefüllt werden
          </mat-error>


          <mat-form-field appearance="fill" class="mat-form-field-text-input">
            <mat-label>Sekretariat - Email</mat-label>
            <input
              matInput
              formControlName="secretaryEmail"/>
            <mat-error *ngIf="createInstanceForm.get('secretaryEmail')?.hasError('required')">
              Muss ausgefüllt werden
            </mat-error>
            <mat-error *ngIf="createInstanceForm.get('secretaryEmail')?.hasError('invalidEmail')">
              Ungültige E-Mail-Adresse
            </mat-error>
          </mat-form-field>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-footer class="mat-card-footer-submit">
      <mat-spinner
        *ngIf="(loadingSubmit$ | async) === true"
        [diameter]="25">
      </mat-spinner>
      <button mat-raised-button color="primary"
              [disabled]="createInstanceForm.invalid || (loadingSubmit$ | async) === true"
              (click)="submitNewInstance()">Erstellen
      </button>
    </mat-card-footer>
  </mat-card>
</div>

<div class="container-response">
  <mat-card
    *ngIf="submitResponse || unknownResponseError"
    [ngClass]="
      {
      'response-successful' : submitResponse && submitResponse.successful,
      'response-not-successful': ((submitResponse && !submitResponse.successful) || unknownResponseError)
      }"
    class="mat-card-response">
    <mat-card-header>
      <mat-card-title *ngIf="submitResponse && submitResponse.successful">
        Kundeninstanz wurde erfolgreich erstellt!
      </mat-card-title>
      <mat-card-title *ngIf="(submitResponse && !submitResponse.successful) || unknownResponseError">
        Die neue Kundeninstanz konnte nicht erfolgreich erstellt werden!
      </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="(submitResponse  && submitResponse.successful && submitResponse.url)">
      Sie können die neue Instanz unter <a href="{{submitResponse.url}}" target="_blank"> {{ submitResponse.url}}</a>
      abrufen.
    </mat-card-content>
  </mat-card>
</div>
