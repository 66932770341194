import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject, take} from "rxjs";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-modal-task-approval',
  templateUrl: './modal-task-approval.component.html',
  styleUrls: ['./modal-task-approval.component.scss']
})
export class ModalTaskApprovalComponent implements OnInit {

  confirmSpinner$ = new BehaviorSubject(false);

  constructor(private dialogRef: MatDialogRef<ModalTaskApprovalComponent>,
              private requestManager: RequestManagerService,
              @Inject(MAT_DIALOG_DATA) public data: { outboxId: string },
              private notifier: NotifierService) {
  }

  ngOnInit(): void {
  }

  clickCancelButton() {
    this.dialogRef.close(false);
  }

  clickSaveButton() {
    this.confirmSpinner$.next(true);

    this.requestManager.post('/outbox/' + this.data.outboxId + '/taskDone', {})
      .pipe(
        take(1)
      )
      .subscribe(() => {
        this.confirmSpinner$.next(false);
        this.dialogRef.close(true);
      }, (error) => {
        this.confirmSpinner$.next(false);
        this.notifier.notify("error", 'Es gab einen Fehler beim Bestätigen der Aufgabe! Melden Sie Ihr Problem bei support@riecken.io!')
      })

  }

}
