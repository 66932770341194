import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {BehaviorSubject, catchError, map, Observable, of, tap} from "rxjs";


@Component({
  selector: 'app-sync-mails-periods-assessments-settings',
  templateUrl: './sync-mails-periods-assessments-settings.component.html',
  styleUrls: ['./sync-mails-periods-assessments-settings.component.css']
})
export class SyncMailsPeriodsAssessmentsSettingsComponent implements OnInit {

  syncSettingsForm = this.fb.group({
    outboxType: this.fb.control(null, [Validators.required]),
    displayCommittingEmployeeAsSender: this.fb.control('1', [Validators.required]),
    firstDocumentReminderAfter: this.fb.control(24, [Validators.min(1)]),
    secondDocumentReminderAfter: this.fb.control(48, [Validators.min(1)]),
    thirdDocumentReminderAfter: this.fb.control(72, [Validators.min(1)]),
    synchroniseOutboxesActive: this.fb.control(false),
    documentStatusIdForClientTasks: this.fb.control(null, [Validators.required]),
    documentStatusIdForClientTaskApproval: this.fb.control(null, [Validators.required]),
    lastSynchronisationOutboxes: this.fb.control(null),
    syncOutboxesFromDate: this.fb.control(null, [Validators.required])
  });

  documentStates$: Observable<any> | null = null;

  loadingSubmitSyncSettingsForm$ = new BehaviorSubject(false);
  $orderTypes: Observable<any> | null = null;
  loadingOutboxTypeToggle$ = new BehaviorSubject(false);

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService) {

    this.loadingOutboxTypeToggle$.next(true);

    this.documentStates$ = this.requestManager.getForInstance('dms/states/').pipe(map((states) => {

        return Object.values(states);
      }), catchError(() => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Status ! Melden Sie Ihr Problem bei support@riecken.io!');
        return of([]);
      })
    );

    this.requestManager.get('dms/settings/')
      .pipe(
      ).subscribe((settings) => {


      this.syncSettingsForm.patchValue({
        displayCommittingEmployeeAsSender: settings.displayCommittingEmployeeAsSender,
        firstDocumentReminderAfter: settings.firstDocumentReminderAfter.toString().split(' hours')[0] ?? 24,
        secondDocumentReminderAfter: settings.secondDocumentReminderAfter.toString().split(' hours')[0] ?? 48,
        thirdDocumentReminderAfter: settings.thirdDocumentReminderAfter.toString().split(' hours')[0] ?? 72,
        synchroniseOutboxesActive: settings.synchroniseOutboxesActive === '1',
        lastSynchronisationOutboxes: settings.lastSynchronisationOutboxes,
        syncOutboxesFromDate: settings.syncOutboxesFromDate ? new Date(settings.syncOutboxesFromDate) : null,
        documentStatusIdForClientTasks: settings.documentStatusIdForClientTasks ?? null,
        documentStatusIdForClientTaskApproval: settings.documentStatusIdForClientTaskApproval ?? null
      });

      if (settings.outboxType !== '') {
        this.syncSettingsForm.patchValue({
          outboxType: Number.parseInt(settings.outboxType),
        });
      }
    });


    this.$orderTypes = this.requestManager.get('outbox-types').pipe(
      tap(() => {
        this.loadingOutboxTypeToggle$.next(false);
      }),
      catchError((error) => {
        console.log(error);
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Postausgangsarten!');
        return of([]);
      })
    );
  }

  ngOnInit(): void {
  }

  submitSyncSettingsForm() {
    this.loadingSubmitSyncSettingsForm$.next(true);
    const body = this.syncSettingsForm.value;
    body.displayCommittingEmployeeAsSender = this.syncSettingsForm.get('displayCommittingEmployeeAsSender')?.value;
    body.firstDocumentReminderAfter = this.syncSettingsForm.get('firstDocumentReminderAfter')?.value + ' hours';
    body.secondDocumentReminderAfter = this.syncSettingsForm.get('secondDocumentReminderAfter')?.value + ' hours';
    body.thirdDocumentReminderAfter = this.syncSettingsForm.get('thirdDocumentReminderAfter')?.value + ' hours';
    body.synchroniseOutboxesActive = this.syncSettingsForm.get('synchroniseOutboxesActive')?.value ? '1' : '0';
    body.documentStatusIdForClientTasks = this.syncSettingsForm.get('documentStatusIdForClientTasks')?.value;
    body.documentStatusIdForClientTaskApproval = this.syncSettingsForm.get('documentStatusIdForClientTaskApproval')?.value;

    const valueSyncOutboxesFromDateInput = this.syncSettingsForm.get('syncOutboxesFromDate')?.value;
    if (valueSyncOutboxesFromDateInput._d) {
      body.syncOutboxesFromDate = this.transformSyncFromDate(valueSyncOutboxesFromDateInput._d);
    } else {
      body.syncOutboxesFromDate = this.transformSyncFromDate(valueSyncOutboxesFromDateInput);
    }


    this.requestManager.put('settings/', body)
      .subscribe(
        () => {
          this.loadingSubmitSyncSettingsForm$.next(false);

        },
        () => {
          this.notifier.notify('error', 'Es gab einen Fehler beim Speichern Einstellungen! Melden Sie Ihr Problem bei support@riecken.io!');

          this.loadingSubmitSyncSettingsForm$.next(false);
        }
      )
  }


  transformSyncFromDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());
    const milliseconds = this.padZero(date.getMilliseconds(), 3);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  padZero(value: number, length: number = 2): string {
    return value.toString().padStart(length, '0');
  }
}
