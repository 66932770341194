import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-confirm-download-documents',
  templateUrl: './modal-confirm-download-documents.component.html',
  styleUrls: ['./modal-confirm-download-documents.component.scss']
})
export class ModalConfirmDownloadDocumentsComponent {

  showCancelButton = false;


  constructor(private dialogRef: MatDialogRef<ModalConfirmDownloadDocumentsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { downloaded: boolean }
  ) {
    this.showCancelButton = data.downloaded;
  }

  clickCancelButton() {
    this.dialogRef.close(false);
  }

  clickSaveButton() {
    this.dialogRef.close(true);
  }
}
