import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {


  constructor(private dialogRef: MatDialogRef<ModalConfirmDeleteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { clientNumber: string }
  ) {
  }

  clickCancelButton() {
    this.dialogRef.close(false);
  }

  clickSaveButton() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }
}
