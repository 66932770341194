import {Component, OnDestroy, OnInit} from '@angular/core';
import {RequestManagerService} from "./services/request-manager.service";
import {NavigationEnd, Router} from "@angular/router";
import {ColorsService} from "./services/colors.service";
import {LoginService} from "./services/login.service";
import {interval, startWith, Subscription, take, tap} from "rxjs";
import {
  ModalDatevconnectionProblemsComponent
} from "./components/modal-datevconnection-problems/modal-datevconnection-problems.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'meineKanzlei-frontend';

  hideNavbarRoutes: string[] = ['password', 'admin/instance/create'];
  showNavbar: boolean = true;
  subscriptionDATEVCheck: Subscription | null = null;
  isLoggedInSubscription: Subscription | null = null;
  isModalOpen: boolean = false;

  constructor(private requestManager: RequestManagerService,
              private router: Router,
              private colorService: ColorsService,
              private loginService: LoginService,
              private matDialogService: MatDialog) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setShowNavbar();
      }
    });


    if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken')) {
      this.loginService.setIsLoggedIn(true);
    }

    this.isLoggedInSubscription = this.loginService.getIsLoggedIn()
      .subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.startDATEVCheckSubscription();
        } else {
          this.stopDATEVCheckSubscription();
        }
      });

    const baseUrl = localStorage.getItem('baseUrl');
    if (baseUrl !== null && baseUrl !== '') {
      this.requestManager.setBaseUrlAbsolute(baseUrl);
    } else {

      const url = window.location.toString()
        .replace("https://", "")
        .replace("http://", "")
        .split("/")[0];
      this.requestManager.setBaseUrl(this.requestManager.getInstanceUrl(url));
    }

  }


  setShowNavbar() {
    const currentRoute = this.router.url.split('/')[1];
    const isPasswordAssignmentRoute = currentRoute === 'user' && this.router.url.includes('/password/');
    const isCreateInstanceRoute = currentRoute === 'admin' && this.router.url.endsWith('/admin/instance/create');
    this.showNavbar = !this.hideNavbarRoutes.includes(currentRoute) && !isPasswordAssignmentRoute && !isCreateInstanceRoute;
  }


  ngOnInit() {
    this.colorService.loadColors(true, true);
  }

  ngOnDestroy() {
    this.stopDATEVCheckSubscription();
    if (this.isLoggedInSubscription) {
      this.isLoggedInSubscription.unsubscribe();
    }
  }

  startDATEVCheckSubscription() {
    if (this.subscriptionDATEVCheck == null) {
      this.subscriptionDATEVCheck = interval(15000).pipe(startWith(0))
        .subscribe(() => {
          this.requestManager.getForInstance('datevStatus')
            .pipe(take(1))
            .subscribe((response) => {
              if (!response.allOk) {
                this.openModal();
              }
            }, (error) => {
              if (error.status !== undefined && (error.status === 502 || error.status === 503 || error.status === 504)) {
                this.openModal();
              }
            });
        });
    }
  }

  openModal() {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      const dialogRef = this.matDialogService.open(ModalDatevconnectionProblemsComponent, {});

      dialogRef.afterClosed().subscribe(result => {
        this.isModalOpen = false; // Modal-Status zurücksetzen, wenn es geschlossen wird
      });
    }

  }

  stopDATEVCheckSubscription() {
    if (this.subscriptionDATEVCheck) {
      this.subscriptionDATEVCheck.unsubscribe();
      this.subscriptionDATEVCheck = null;
    }
  }
}
