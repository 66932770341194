import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, switchMap, take, tap} from "rxjs";
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {MatDialog} from "@angular/material/dialog";
import {UserClientsModalComponent} from "../userClients/user-clients-modal/user-clients-modal.component";
import {ModalConfirmDeleteUserComponent} from "./modal-confirm-delete-user/modal-confirm-delete-user.component";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  addedUsers$: Observable<any>

  loadingAddUser$ = new BehaviorSubject(false);

  $reload = new BehaviorSubject(false);
  userHideShow: any = {};

  limit = 10;
  limitOptions = [5, 10, 25, 50, 100];
  offset = 0;
  nextPageDisabled = false;

  addUserForm = this.fb.group({
    email: this.fb.control('', [Validators.required]),
    name: this.fb.control('', [Validators.required]),
    surname: this.fb.control('', [Validators.required]),
    isEmployee: this.fb.control(false, [Validators.required]),
  });


  constructor(
    private requestManager: RequestManagerService,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private matDialogService: MatDialog,
  ) {
    this.addedUsers$ = combineLatest([this.$reload]).pipe(
      switchMap(([reload]: any) => {
        if (reload) {
          return this.requestManager.getForInstance('users' + '?offset=' + this.offset + '&limit=' + this.limit).pipe(
            tap((users: any) => {

              this.userHideShow = {};

              if (Array.isArray(users)) {
                if (users.length === 0) {
                  this.nextPageDisabled = true;
                } else {
                  this.nextPageDisabled = false;
                }
                for (let user of users) {
                  this.userHideShow[user.id] = 'hide';
                }
              } else {
                this.nextPageDisabled = true;
              }

            })
          );
        }
        return of([]);
      }));
  }

  ngOnInit(): void {
    this.$reload.next(true);
  }

  createUser() {
    this.loadingAddUser$.next(true);
    const body = this.addUserForm.value;
    body.email = body.email.toString().toLowerCase();

    this.requestManager.post('users/create', body)
      .subscribe(
        () => {
          this.resetAddUserForm();
          this.$reload.next(true);
          this.loadingAddUser$.next(false);

        },
        (error) => {
          if (error.status != undefined && error.status != null && error.status === 409) {
            this.notifier.notify('error', 'Es gibt schon einen Benutzer mit der angegebenen E-Mail-Adresse!');
          } else {
            this.notifier.notify('error', 'Es gab einen Fehler beim Erstellen eines neuen Benutzers! Melden Sie Ihr Problem bei support@riecken.io!');
          }
          this.loadingAddUser$.next(false);
        }
      )
  }

  showOrHideUserClients(userId: number) {
    if (this.userHideShow[userId] === 'hide') {
      this.userHideShow[userId] = 'show';
    } else if (this.userHideShow[userId] === 'show') {
      this.userHideShow[userId] = 'hide';
    }
  }

  numberOfClientsOfUser(addedUser: any): number {
    if (addedUser.userHasClients !== undefined
      && addedUser.userHasClients !== null
      && Array.isArray(addedUser.userHasClients)) {
      return Object.values(addedUser.userHasClients).length;
    }

    return 0;
  }

  updateUserEmployee(addedUser: any, newStatus: boolean) {
    this.loadingAddUser$.next(true);
    this.requestManager.put('users/' + addedUser.id + '/isEmployee/status/' + newStatus, {})
      .subscribe(
        () => {
          this.$reload.next(true);
          this.loadingAddUser$.next(false);
        },
        () => {

          this.notifier.notify('error', 'Es gab einen Fehler beim Bearbeiten des Benutzers! Melden Sie Ihr Problem bei support@riecken.io!');
          this.loadingAddUser$.next(false);
        }
      )
  }

  openUserClientModal(addedUser: any) {
    const dialogRef = this.matDialogService.open(UserClientsModalComponent,
      {
        data:
          {
            userId: addedUser.id
          },
        minHeight: 'calc(100vh-7.5rem)',
        height: 'auto',
      });

    dialogRef.afterClosed().subscribe(
      () => {
        this.$reload.next(true);
      }, () => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Mandanten passiert! Melden Sie Ihr Problem bei support@riecken.io!');
        this.$reload.next(true);
      }
    )
  }

  updateUserAdmin(addedUser: any, newStatus: boolean) {
    this.loadingAddUser$.next(true);
    this.requestManager.put('users/' + addedUser.id + '/isAdmin/status/' + newStatus, {})
      .subscribe(
        () => {
          this.$reload.next(true);
          this.loadingAddUser$.next(false);
        },
        () => {

          this.notifier.notify('error', 'Es gab einen Fehler beim Bearbeiten des Benutzers! Melden Sie Ihr Problem bei support@riecken.io!');
          this.loadingAddUser$.next(false);
        }
      )
  }

  getNextPage() {
    this.offset += this.limit;

    this.$reload.next(true);
  }

  getPreviousPage() {
    this.offset -= this.limit;
    if (this.offset < 0) {
      this.offset = 0;
    }
    this.$reload.next(true);
  }

  reloadUsers() {
    this.$reload.next(true);
  }

  previousPageButtonIsDisabled() {
    return (this.offset - this.limit) < 0;
  }

  private resetAddUserForm() {
    this.addUserForm = this.fb.group({
      email: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      surname: this.fb.control('', [Validators.required]),
      isEmployee: this.fb.control(false, [Validators.required]),
    });

  }

  deleteUser(addedUser: any) {
    const modalRef = this.matDialogService.open(ModalConfirmDeleteUserComponent, {
      data: {
        email: addedUser.email
      }
    });

    modalRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.requestManager.delete('users/' + addedUser.id + '/delete')
            .pipe(
              take(1)
            )
            .subscribe(
              () => {
                this.$reload.next(true);
                this.loadingAddUser$.next(false);
              },
              () => {

                this.notifier.notify('error', 'Es gab einen Fehler beim Löschen des Benutzers! Melden Sie Ihr Problem bei support@riecken.io!');
              }
            );
        }
        ;
      });
  }
}
