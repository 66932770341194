import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {
  catchError,
  of,
  switchMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  interval,
  startWith,
  take,
  Subscription
} from "rxjs";
import {ColorsService} from "../../../services/colors.service";
import {
  SettingResponseSyncDocumentsSubsequently,
} from "../../../services/data-interfaces";

export function customEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (!email) {
      return null; // Wenn das Feld leer ist, kümmert sich der "required"-Validator darum
    }

    // RegEx für eine strengere E-Mail-Validierung
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(email) ? null : {invalidEmail: true};
  };
}

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.css']
})
export class GeneralSettingsComponent implements OnInit, OnDestroy {

  generalSettingsForm = this.fb.group({
    tabOrderActive: this.fb.control(false, [Validators.required]),
    showAmountsInOrderTab: this.fb.control(false, [Validators.required]),
    dataProtectionModeActive: this.fb.control('1', [Validators.required]),
    secretaryEmail: this.fb.control("", [Validators.required, customEmailValidator()]), // Benutzerdefinierter E-Mail-Validator hinzugefügt  });
  });

  primaryColor = '#FFFFFF';
  secondaryColor = '#2196F3';

  checkSyncDocumentsSubsequentlySubscription: Subscription | null = null
  processSyncDocumentsSubsequently = '0';
  syncDocumentsSubsequentlyRunning = '0';

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private colorService: ColorsService) {

    this.requestManager.get('general/settings/')
      .subscribe((settings) => {

        this.generalSettingsForm.patchValue({
          tabOrderActive: settings.tabOrderActive === '1',
          showAmountsInOrderTab: settings.showAmountsInOrderTab === '1',
          dataProtectionModeActive: settings.dataProtectionModeActive,
          secretaryEmail: settings.secretaryEmail
        });

        this.startCheckSyncDocumentsSubsequentlySubscription();

        // Subscription für das gesamte Formular, außer dem secretaryEmail Feld
        this.generalSettingsForm.valueChanges
          .pipe(
            filter(() => !this.generalSettingsForm.get('secretaryEmail')?.dirty), // Ignoriere Änderungen bei secretaryEmail
            distinctUntilChanged(),
            switchMap((formValues) => {
              if (formValues.dataProtectionModeActive === '0') {
                this.syncDocumentsSubsequentlyRunning = '1';
              }
              const {secretaryEmail, ...otherValues} = formValues; // Ignoriere secretaryEmail
              return this.requestManager.put('general/settings/', otherValues);
            }),
            catchError((error) => {
              console.log(error);
              this.notifier.notify('error', 'Es gab einen Fehler beim Bearbeiten der allgemeinen Einstellungen!');
              return of(null);
            })
          ).subscribe();

        // Separate Subscription für das secretaryEmail Feld mit debounce
        this.generalSettingsForm.get('secretaryEmail')?.valueChanges
          .pipe(
            debounceTime(500), // Warte 500ms, nachdem der Nutzer aufgehört hat zu tippen
            distinctUntilChanged(),
            switchMap((email) => {
              return this.requestManager.put('general/settings/', {secretaryEmail: email});
            }),
            catchError((error) => {
              console.log(error);
              this.notifier.notify('error', 'Es gab einen Fehler beim Bearbeiten der E-Mail-Adresse des Sekretariats!');
              return of(null);
            })
          ).subscribe();
      });

    this.colorService.loadColors(false)
      .subscribe((colors) => {
        if (colors !== null) {
          this.primaryColor = colors.primaryColor;
          this.secondaryColor = colors.secondaryColor;
        }
      });

  }

  startCheckSyncDocumentsSubsequentlySubscription() {
    if (this.checkSyncDocumentsSubsequentlySubscription == null) {

      this.checkSyncDocumentsSubsequentlySubscription = interval(10000)
        .pipe(startWith(0))
        .subscribe(() => {
          this.requestManager.getForInstance('general/settings/syncDocumentsSubsequently')
            .pipe(take(1))
            .subscribe((response: SettingResponseSyncDocumentsSubsequently) => {
              this.processSyncDocumentsSubsequently = response.processSyncDocumentsSubsequently;
              this.syncDocumentsSubsequentlyRunning = response.syncDocumentsSubsequentlyRunning;
            }, (error) => {

            });
        });
    }
  }

  ngOnInit(): void {
  }

  submitColors() {
    this.colorService.updateColors(this.primaryColor, this.secondaryColor);
  }

  ngOnDestroy() {
    if (this.checkSyncDocumentsSubsequentlySubscription) {
      this.checkSyncDocumentsSubsequentlySubscription.unsubscribe();
    }
  }
}
