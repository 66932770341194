<div class="main-grid">

  <div class="grid-text">
    <h2 *ngIf="user">Willkommen {{user.name + ' ' + user.surname}}. Schön, dass Sie hier sind!</h2>
    <p class="block-text">Auf den folgenden Seiten können Sie nicht nur den Stand Ihrer Aufträge einsehen, sondern auch
      ganz bequem Dokumente an die Kanzlei übermitteln.<br/>
      Sollten Sie mit Ihrem Anliegen einmal nicht weiterkommen, kontaktieren Sie unsere Kanzlei:
      <a href='{{contactLink}}' target="_blank">{{companyName}}</a>
    </p>
  </div>
  <div class="card-documents" (click)="navigateToRoute('/documents')">
    <mat-card>

      <mat-card-content>
        <div class="block-card-icon-title">
          <div class="block-card-icon">
            <img src="assets/icons/Icon_Document.svg"/>
          </div>
          <div>
            <h2 class="block-card-title">Dokumente</h2>
          </div>
        </div>
        <a type="button"
           class="link-button"
           mat-raised-button
        >
          anzeigen
        </a>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="card-orders" (click)="navigateToRoute('/orders')">
    <mat-card>
      <mat-card-content>
        <div class="block-card-icon-title">
          <div class="block-card-icon">
            <img src="assets/icons/Icon_Contract.svg"/>
          </div>
          <div>
            <h2 class="block-card-title">Aufträge</h2>
          </div>
        </div>
        <a type="button"
           class="link-button"
           mat-raised-button
        >
          anzeigen
        </a>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="grid-right" (click)="navigateToRoute('/base-data')">
    <mat-card>
      <mat-card-content>
        <div class="block-card-icon-title">
          <div class="block-card-icon">
            <img src="assets/icons/Icon_Personal Data.svg"/>
          </div>
          <div>
            <h2 class="block-card-title">Persönliche Daten</h2>
          </div>
        </div>
        <a type="button"
           class="link-button"
           mat-raised-button
        >
          anzeigen
        </a>
      </mat-card-content>
    </mat-card>
  </div>



</div>
