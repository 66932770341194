import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../services/request-manager.service";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {NotifierService} from "angular-notifier";
import {BehaviorSubject, tap} from "rxjs";
import {User} from "../../services/data-interfaces";

@Component({
  selector: 'app-base-data-user-page',
  templateUrl: './base-data-user-page.component.html',
  styleUrls: ['./base-data-user-page.component.css']
})
export class BaseDataUserPageComponent implements OnInit {

  // userForm = this.fb.group({
  //   email: this.fb.control('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
  //   name: this.fb.control('', [Validators.required]),
  //   surname: this.fb.control('', [Validators.required])
  // });


  userForm;

  userId: number | null | undefined;
  loadingSaveUserData$ = new BehaviorSubject(false);

  constructor(
    private requestManager: RequestManagerService,
    private fb: FormBuilder,
    private notifier: NotifierService,
  ) {
    this.userForm = this.fb.group({
      email: new FormControl({value: '',}),
      name: new FormControl({value: '',}),
      surname: new FormControl({value: '',})
    });

    this.requestManager.get('user/self').pipe(
      tap((userData: User) => {

        this.userForm.patchValue({
          email: userData.email,
          name: userData.name,
          surname: userData.surname
        });

        this.userId = userData.id;
      })
    ).subscribe(() => {

    }, () => {
      this.notifier.notify('error', 'Es gab einen Fehler beim Laden deiner Benutzerdaten! Melden Sie Ihr Problem bei support@riecken.io!');
    })
  }

  ngOnInit(): void {
  }

  saveUserData() {
    if (this.userId) {
      this.loadingSaveUserData$.next(true);
      const body = this.userForm.value;
      this.requestManager.put('users/' + this.userId + '/baseData', body)
        .subscribe(
          () => {
            this.loadingSaveUserData$.next(false);
            this.notifier.notify('success', 'Stammdaten erfolgreich gespeichert!');
          },
          () => {

            this.notifier.notify('error', 'Es gab einen Fehler beim Speichern deiner Benutzerdaten! Melden Sie Ihr Problem bei support@riecken.io!');

            this.loadingSaveUserData$.next(false);
          }
        )
    }
  }
}
