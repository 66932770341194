import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Observable, of, switchMap, tap} from "rxjs";
import {RequestManagerService} from "../../services/request-manager.service";
import {User} from "../../services/data-interfaces";

@Component({
  selector: 'app-base-data-clients-page',
  templateUrl: './base-data-clients-page.component.html',
  styleUrls: ['./base-data-clients-page.component.css']
})
export class BaseDataClientsPageComponent implements OnInit {


  userClients$: Observable<any[]>;
  reload$ = new BehaviorSubject(false);

  constructor(
    private requestManager: RequestManagerService,) {

    this.userClients$ = combineLatest([this.reload$,
      this.requestManager.get('user/self')]).pipe(
      switchMap(([reload, userData]: any) => {
        // console.log(reload);
        if (reload && userData.id) {
          return this.requestManager.getForInstance('users/' + userData.id + '/userClients').pipe(
            map((userClients: any) => {

              if (userClients && Array.isArray(userClients)) {
                const clients = [];
                for (const userClient of userClients) {
                  // console.log(userClient);
                  if (userClient.client && Array.isArray(userClient.client)) {
                    for (const client of userClient.client) {
                      clients.push(client);
                    }
                  }
                }
                return clients;
              }
              return [];
            })
          );
        }
        return of([]);
      }));

  }

  ngOnInit(): void {
    this.reload$.next(true);
  }

}
