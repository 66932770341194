import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-datevconnection-problems',
  templateUrl: './modal-datevconnection-problems.component.html',
  styleUrls: ['./modal-datevconnection-problems.component.scss']
})
export class ModalDatevconnectionProblemsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalDatevconnectionProblemsComponent>) {
  }

  ngOnInit(): void {
  }

  clickCancelButton() {
    this.dialogRef.close(false);
  }

  clickSaveButton() {
    this.dialogRef.close(true);
  }
}
