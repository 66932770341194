import {Component, OnInit} from '@angular/core';
import {User} from "../../services/data-interfaces";
import {Router} from "@angular/router";
import {RequestManagerService} from "../../services/request-manager.service";

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  user: User | null = null;
  contactLink = null;
  companyName = null;

  constructor(private router: Router,
              private requestManager: RequestManagerService) {
    if (localStorage.getItem('user') !== null) {
      const userData = localStorage.getItem('user') ?? '';
      this.user = JSON.parse(userData);
      this.requestManager.get('general/settings/')
        .pipe(
        ).subscribe((settings) => {
        this.contactLink = settings.contactLink;
        this.companyName = settings.companyName;
      });

    }
  }

  ngOnInit(): void {
  }

  navigateToRoute(route: string) {
    this.router.navigateByUrl(route);
  }
}
