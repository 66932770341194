<div class="header__background">
  <mat-toolbar role="toolbar" class="task-header">
    <h1 mat-dialog-title>{{'Mandanten des Benutzers'}}</h1>

    <span class="fx-spacer"></span>
    <button
      (click)="clickClose()"
      [disabled]="(loading$ | async) === true" mat-icon-button>
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<mat-dialog-content>

  <h3>Mandanten des Benutzers: </h3>
  <ng-container *ngIf="(userClients$ | async) as userClients">
    <div *ngIf="userClients.length > 0" class="client-list">
      <div class="table-head table-row-userClient-head">
        <div>
          <mat-card-subtitle>
            Mandantennummer
          </mat-card-subtitle>
        </div>
        <div>
          <mat-card-subtitle>
            Name
          </mat-card-subtitle>
        </div>
        <div>
          <mat-card-subtitle>
            Aktion
          </mat-card-subtitle>
        </div>
      </div>
      <ng-container *ngFor="let userClient of userClients">
        <mat-divider></mat-divider>
        <div class="table-row-userClient">
          <div class="table-row-align-middle">{{userClient.clientNumber ?? '-'}}</div>
          <div class="table-row-align-middle">{{userClient.name ?? '-'}}</div>
          <div class="table-row-align-middle">
            <button mat-raised-button
                    color="warn"
                    (click)="deleteUserClient(userClient)">Löschen
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="userClients.length === 0" class="client-list">
      <p>Keine Mandanten hinzugefügt!</p>
    </div>
  </ng-container>


  <div class="form-flex">
    <ng-container *ngIf="(addableClients$ | async) as addableClients ">
      <form [formGroup]="addNewClientForm" class="addNewClientForm">
        <mat-form-field appearance="fill" class="select-add-client">
          <mat-label>Mandaten zum Hinzufügen</mat-label>
          <mat-select formControlName="clientId">
            <mat-option>
              <ngx-mat-select-search
                formControlName="clientsSearch"
                placeholderLabel="Mandantenname oder Nummer eingeben "
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let addableClient of filteredAddableClients" value="{{addableClient.id}}">
              {{addableClient.clientNumber + ': ' + addableClient.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div>
        <button mat-raised-button color="primary"
                (click)="addClientToUser()"
                [disabled]="addNewClientForm.invalid || (loading$ | async) === true"
        >Hinzufügen
        </button>
      </div>
      <mat-spinner
        *ngIf="(loading$ | async) === true"
        [diameter]="25">
      </mat-spinner>
    </ng-container>
  </div>

</mat-dialog-content>

<div class="footer">
  <mat-dialog-actions align="end">
    <mat-progress-spinner
      *ngIf="(loading$ | async) === true"
      mode="indeterminate"
      [diameter]="33"
      color="primary"></mat-progress-spinner>
    <button
      mat-raised-button color="primary"
      [disabled]="(loading$ | async) === true"
      (click)="clickClose()">{{"Schließen"}}</button>

  </mat-dialog-actions>
</div>
