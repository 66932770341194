import {Injectable} from '@angular/core';
import {LoginResponse, User} from "./data-interfaces";
import {Router} from "@angular/router";
import {LoginService} from "./login.service";

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {

  constructor(private router: Router) {
  }

  public setSession(loginResponse: LoginResponse, baseUrl: string, userData: User) {

    if (userData) {
      localStorage.setItem('user', JSON.stringify(userData));
    }
    localStorage.setItem('baseUrl', baseUrl);
    localStorage.setItem('accessToken', loginResponse.accessToken);
    localStorage.setItem('refreshToken', loginResponse.refreshToken);
    localStorage.setItem('isLoggedIn', '1');

  }

  public setToken(loginResponse: LoginResponse) {

    localStorage.setItem('accessToken', loginResponse.accessToken);
    localStorage.setItem('refreshToken', loginResponse.refreshToken);

  }


  public logout() {
    this.unsetSession();
    this.router.navigateByUrl('login');
  }

  public unsetSession() {
    localStorage.clear();
  }
}
