import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {SessionManagerService} from "./session-manager.service";
import {LoginService} from "./login.service";
import {take} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private sm: SessionManagerService,
    private loginService: LoginService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const sessionValidationPromise = new Promise((resolve, reject) => {
      this.loginService.getIsLoggedIn()
        .pipe(take(1))
        .subscribe((value) => {

          if (localStorage.getItem('accessToken') && localStorage.getItem('accessToken')) {

            this.loginService.setIsLoggedIn(true);
            resolve(true);
          } else {
            this.loginService.setIsLoggedIn(false);
            this.sm.logout();
          }
        })
    });

    return sessionValidationPromise;
  }
}
