<app-navbar>
  <div [ngClass]="{'block-router' : showNavbar,'block-center' : !showNavbar}">
    <router-outlet>
    </router-outlet>
  </div>
</app-navbar>


<div class="notifier">
  <notifier-container></notifier-container>
</div>
