<mat-card>
  <mat-card-header>
    <div class="mat-card-header-client-list">
      <mat-card-title-group>
        <mat-card-title>
          Meine Mandanten
        </mat-card-title>
        <mat-card-subtitle>
          Hier siehst du alle Mandanten, welche dir im Portal zugeordnet sind
        </mat-card-subtitle>
      </mat-card-title-group>
    </div>
  </mat-card-header>
  <mat-card-content class="mat-card-content-list">
    <div class="table">
      <div class="orderTableGrid ">


        <div
          class="table-head">
          Mandantennummer
        </div>

        <div class="table-head">
          <mat-card-subtitle>
            Name
          </mat-card-subtitle>
        </div>

        <div class="table-head">
          <mat-card-subtitle>
            UID
          </mat-card-subtitle>
        </div>

      </div>
      <ng-container *ngIf="(userClients$ | async) as userClients">

        <div *ngFor="let client of userClients" class="table-row orderTableGrid">
          <mat-divider></mat-divider>
          <div class=" table-row-align-middle">
            {{client.clientNumber ?? '-'}}
          </div>
          <div class="table-row-align-middle">
            {{client.name ?? '-'}}
          </div>

          <div class="table-row-align-middle">
            {{ client.vatId ?? '-'}}
          </div>
        </div>
        <div
          *ngIf="!userClients || userClients.length === 0"
          class="block-no-clients">
          Keine Mandanten!
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
