import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../services/request-manager.service";
import {BehaviorSubject} from "rxjs";
import {NotifierService} from "angular-notifier";

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {

  userData;
  loadingSync$ = new BehaviorSubject(false);

  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService,) {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.userData = JSON.parse(userData);
    }
  }

  ngOnInit(): void {
  }

  startSync() {
    this.loadingSync$.next(true);
    this.requestManager.getForInstance('userClient/synchronise')
      .subscribe(() => {
          this.notifier.notify('success', 'Die Synchronisation wurde erfolgreich abgeschlossen!');
          this.loadingSync$.next(false);
        },
        () => {
          this.notifier.notify('error', 'Es gab einen Fehler bei der Synchronisation der Benutzer mit Mandanten! Melden Sie Ihr Problem bei support@riecken.io!');
          this.loadingSync$.next(false);
        })
  }

}
