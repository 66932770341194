<mat-card>
  <mat-card-header>
    <mat-card-title>
      Benutzer
    </mat-card-title>
    <div class="pagination-buttons">
      <mat-form-field>
        <mat-label>Max. Benutzer pro Seite</mat-label>
        <mat-select [(ngModel)]="limit" (ngModelChange)="reloadUsers()">
          <mat-option *ngFor="let limitOption of limitOptions" [value]="limitOption">
            {{limitOption}}</mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-button
              class="secondary-color"
              [disabled]="previousPageButtonIsDisabled()"
              (click)="getPreviousPage()">
        <mat-icon color="secondary">chevron_left</mat-icon>
        Vorherige Seite
      </button>
      <button mat-button
              color="secondary"
              class="secondary-color"
              [disabled]="nextPageDisabled"
              (click)="getNextPage()">Nächste Seite
        <mat-icon color="secondary">chevron_right</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="table-head orderTableGrid">
      <div>
        <mat-card-subtitle>
          E-Mail
        </mat-card-subtitle>
      </div>
      <div>
        <mat-card-subtitle>
          Nachname
        </mat-card-subtitle>
      </div>
      <div>
        <mat-card-subtitle>
          Vorname
        </mat-card-subtitle>
      </div>
      <div>
        <mat-card-subtitle>
          Mitarbeiter
        </mat-card-subtitle>
      </div>
      <div class="">
        <mat-card-subtitle>
          Administrator
        </mat-card-subtitle>
      </div>
      <div class="">
        <mat-card-subtitle>
          Aktionen
        </mat-card-subtitle>
      </div>
    </div>
    <ng-container *ngIf="(addedUsers$ | async) as addedUsers">
      <ng-container *ngFor="let addedUser of addedUsers">
        <div class="orderTableGrid table-row ">
          <mat-divider></mat-divider>
          <div class="table-row-align-middle">
            <span>{{addedUser.email.toString().toLowerCase() ?? '-'}}</span>

          </div>
          <div class="table-row-align-middle">
            {{addedUser.name ?? '-'}}
          </div>
          <div class="table-row-align-middle">
            {{ addedUser.surname ?? '-'}}
          </div>
          <div class=" table-row-align-middle">
            {{addedUser.isEmployee === '1' ? 'Ja' : 'Nein'}}
          </div>
          <div class=" table-row-align-middle">
            {{addedUser.isAdmin === '1' ? 'Ja' : 'Nein'}}
          </div>
          <div class=" table-row-align-action">
            <button mat-raised-button
                    color="primary"
                    (click)="updateUserEmployee(addedUser,addedUser.isEmployee!=='1')"> {{addedUser.isEmployee === '1' ? 'Als Mitarbeiter entfernen' : 'Als Mitarbeiter markieren'}}</button>


            <button mat-raised-button
                    color="primary"
                    *ngIf="addedUser.isEmployee!=='1'"
                    (click)="openUserClientModal(addedUser)">Mandanten bearbeiten
            </button>
            <button mat-raised-button
                    color="accent"
                    (click)="updateUserAdmin(addedUser,addedUser.isAdmin!=='1')"> {{addedUser.isAdmin === '1' ? 'Als Administrator entfernen' : 'Als Administrator markieren'}}</button>
            <button mat-raised-button
                    color="warn"
                    (click)="deleteUser(addedUser)">Benutzer löschen
            </button>

          </div>


        </div>
      </ng-container>
      <div
        *ngIf="!addedUsers || addedUsers.length === 0"
        class="block-no-users">
        Keine Benutzer!
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>


<mat-card class="card-margin">
  <mat-card-header>
    <mat-card-title>
      Benutzer erstellen
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div [formGroup]="addUserForm" class="form">

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>E-Mail</mat-label>
        <input
          matInput
          formControlName="email"/>
        <mat-error *ngIf="addUserForm.get('email')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Vorname</mat-label>
        <input
          matInput
          formControlName="name"/>
        <mat-error *ngIf="addUserForm.get('name')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Nachname</mat-label>
        <input
          matInput
          formControlName="surname"/>
        <mat-error *ngIf="addUserForm.get('surname')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>
      <mat-slide-toggle formControlName="isEmployee">Ist Mitarbeiter</mat-slide-toggle>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <button mat-raised-button
            color="primary"
            class="btn-add-client primary-background-color"
            (click)="createUser()"
            [disabled]="addUserForm.invalid || (loadingAddUser$ | async) === true">
      Benutzer erstellen
    </button>
    <mat-spinner class="spinner-loading-add-client"
                 *ngIf="(loadingAddUser$ | async) === true"
                 [diameter]="25">
    </mat-spinner>
  </mat-card-footer>
</mat-card>
