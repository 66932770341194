import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../services/request-manager.service";
import {BehaviorSubject} from "rxjs";
import {CreateInstanceResponse} from "../../../services/data-interfaces";
import {customEmailValidator} from "../../admin-dashboard/general-settings/general-settings.component";


@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss']
})
export class CreateInstanceComponent {

  createInstanceForm = this.fb.group({
    adminEmail: this.fb.control('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    adminPassword: this.fb.control('', [Validators.required]),
    name: this.fb.control('', [Validators.required]),
    datevConnectUsername: this.fb.control('', [Validators.required]),
    datevConnectPassword: this.fb.control('', [Validators.required]),
    imprintLink: this.fb.control('', [Validators.required]),
    contactLink: this.fb.control('', [Validators.required]),
    companyName: this.fb.control('', [Validators.required]),
    secretaryEmail: this.fb.control("", [Validators.required, customEmailValidator()]), // Benutzerdefinierter E-Mail-Validator hinzugefügt  });
  });

  loadingSubmit$ = new BehaviorSubject(false);

  submitResponse: CreateInstanceResponse | null = null;
  unknownResponseError: boolean = false;

  constructor(private fb: FormBuilder,
              private requestManager: RequestManagerService) {
  }

  public submitNewInstance() {
    this.unknownResponseError = false;
    this.loadingSubmit$.next(true);
    this.requestManager.postForURL(this.requestManager.getBasePath() + 'instance/create', this.createInstanceForm.value).subscribe(
      (response: CreateInstanceResponse) => {
        this.loadingSubmit$.next(false);
        this.submitResponse = response;
      },
      (error) => {
        if (error.error !== undefined && error.error.successful !== undefined) {
          this.submitResponse = error.error;
        } else {
          this.unknownResponseError = true;
        }
        this.loadingSubmit$.next(false);
        console.log(error)
      });
  }
}
