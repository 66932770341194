<mat-card>
  <mat-card-header>
    <mat-card-title>
      Benutzer Stammdaten
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content [formGroup]="userForm">
    <mat-form-field appearance="fill" class="select-add-client">
      <mat-label>Nachname</mat-label>
      <input
        matInput
        formControlName="surname"
        readonly=""/>

    </mat-form-field>
    <mat-form-field appearance="fill" class="select-add-client">
      <mat-label>Vorname</mat-label>
      <input
        matInput
        formControlName="name"
        readonly=""/>

    </mat-form-field>
    <mat-form-field appearance="fill" class="select-add-client">
      <mat-label>E-Mail</mat-label>
      <input
        matInput
        formControlName="email"
        readonly=""/>
    </mat-form-field>
  </mat-card-content>
</mat-card>

