import {Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {RequestManagerService} from "./request-manager.service";
import {SessionManagerService} from "./session-manager.service";
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError} from "rxjs";
import {LoginService} from "./login.service";

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private ROUTES_TO_IGNORE_401_ERROR = ['auth', 'auth/', 'auth/refresh', 'auth/refresh/', 'instance/create', 'instance/create/'];


  constructor(private rm: RequestManagerService,
              private sm: SessionManagerService,
              private loginService: LoginService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;

    const token = localStorage.getItem('accessToken');

    if (token != null) {
      if (!req.url.toString().endsWith('auth/refresh')) {
        authReq = this.addTokenHeader(req, token);
      }
    }

    return next.handle(authReq).pipe(catchError(error => {


      /*
      if there was an 401 error, and it was not 'auth' or 'auth/refresh' request,
      handle error, by trying to refresh token
       */
      if (error instanceof HttpErrorResponse && error.status === 401
        && !this.ignoreRoute(authReq.url.toString())) {


        return this.handle401Error(authReq, next);
        /*
        if there was an error at login ('auth' request),
        get error message by status code
        */
      } else if (authReq.url.toString().endsWith('auth/refresh')) {
        this.loginService.setIsLoggedIn(false);
        this.sm.logout();
      }

      return throwError(error);
    })) as Observable<HttpEvent<any>>;
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {

    return request.clone({headers: request.headers.set('Authorization', token)});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {


    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = localStorage.getItem('refreshToken');
      if (token) {
        return this.rm.authRefresh(token).pipe(
          switchMap((accessToken: string) => {

            this.isRefreshing = false;

            this.refreshTokenSubject.next(accessToken);

            return next.handle(this.addTokenHeader(request, accessToken));

          }),
          catchError((error) => {
            console.log(error);
            this.isRefreshing = false;
            return throwError(error);
          }),
        );
      } else {
        this.isRefreshing = false;
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    );

  }


  private ignoreRoute(url: string) {
    let ignoreRoute = false;
    this.ROUTES_TO_IGNORE_401_ERROR.forEach((urlToIgnore) => {

      if (url.endsWith(urlToIgnore)) {
          ignoreRoute = true;
        }
      }
    );
    return ignoreRoute;
  }
}


export const RequestHTTPProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true},
];
