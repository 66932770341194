<div class="header__background">
  <mat-toolbar role="toolbar" class="task-header">
    <h1 mat-dialog-title>{{'Benutzer entfernen'}}</h1>
    <span class="fx-spacer"></span>
    <button
      (click)="clickCancelButton()"
      mat-icon-button>
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <p>Zu dieser Mandantennummer stehen Postausgänge zur Übermittlung bereit. Es gibt jedoch noch keinen Benutzer zu
    dieser Nummer.
    In der Benutzerverwaltung ordnen Sie manuell einen Benutzer zu.
    <br/><br/>
    Möchten Sie den Benutzer für die Mandantennummer {{data.clientNumber}} entfernen?</p>
</div>
<div class="footer" mat-dialog-actions align="end">
  <button
    mat-button (click)="clickCancelButton()">{{'Abbrechen'}}
  </button>
  <button
    mat-button color="warn"
    (click)="clickSaveButton()">
    {{'Bestätigen'}}
  </button>
</div>
