import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, of, take, throwError} from 'rxjs';
import {catchError, map, switchMap, tap, distinctUntilChanged} from 'rxjs/operators';
import {RequestManagerService} from '../../../services/request-manager.service';
import {NotifierService} from 'angular-notifier';
import {DocumentFilterService} from '../../../services/document-filter.service';
import {DocumentPageService} from "../../../services/document-page.service";
import {Client, DMSDocument} from "../../../services/data-interfaces";

interface Employee {
  id: number | string;
  display_name: string;
  status: string;
}

export function atLeastOneFieldValidator(fields: string[]): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const isValid = fields.some(field => {
      const value = form.get(field)?.value;
      return typeof value === 'string' ? value.trim().length > 0 : value != null && value.toString().length > 0;
    });
    return isValid ? null : {atLeastOneFieldRequired: true};
  };
};

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.css']
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {

  loadingSubmit$ = new BehaviorSubject(false);
  loadingEmployees$ = new BehaviorSubject(false);
  @Output() close = new EventEmitter<boolean>();

  fileForm = this.fb.group({
    files: this.fb.control(''),
    fromClientId: this.fb.control('', [Validators.required]),
    toEmployeeId: this.fb.control('', [Validators.required]),
    subject: this.fb.control('', [Validators.required]),
    copyToMe: this.fb.control(false),
    filesToSign: this.fb.control(false, [Validators.required]),
    note: this.fb.control(''),
    answerRequested: this.fb.control(false)
  }, {validators: atLeastOneFieldValidator(['files', 'note'])});

  filesToUpload: { name: string, content: string, type: string }[] = [];

  uploadAvailable$: Observable<boolean>;
  userClients$: Observable<any>;
  filteredEmployees: Employee[] = [];
  allEmployees: Employee[] = [];
  signMode = false;
  secretaryEmail: string | null = null;
  companyName: string | null = null;
  isDragging = false;
  modeFiles: 'SHOW' | 'HIDE' = 'SHOW';

  constructor(
    private requestManager: RequestManagerService,
    private notifier: NotifierService,
    private filterService: DocumentFilterService,
    private fb: FormBuilder,
    private documentService: DocumentPageService
  ) {

    this.requestManager.get('general/settings/')
      .pipe(
      ).subscribe((settings) => {
      this.secretaryEmail = settings.secretaryEmail
      this.companyName = settings.companyName;
    });


    this.documentService.clientNumber$
      .pipe(
        take(1),
        switchMap((clientNumber: string | null) => {
          if (clientNumber) {
            return this.requestManager.get('clients?clientNumber=' + clientNumber);
          }
          return of(null);
        }),
        tap((client: Client) => {
          if (client) {
            this.fileForm.get('fromClientId')?.patchValue(client.id);
          }
        })
      )
      .subscribe(() => {
      }, () => {
      });


    combineLatest(
      [
        this.documentService.employeeName$,
        this.fileForm.get('fromClientId')!.valueChanges
          .pipe(
            tap(() => {

            }),
            switchMap(clientId => this.loadEmployees(clientId)))
      ])
      .pipe(
        switchMap(([employeeNumber, employees]: [number | null, Employee[]]) => {
          if (employeeNumber) {
            return this.requestManager.get('employees?employeeNumber=' + employeeNumber)
              .pipe(
                map((user: any) => {
                  const employee = employees.find((employee) => employee.id === user.id);
                  if (employee) {
                    return employee;
                  }
                  return null;
                })
              );
          }
          return of(null);
        }),
        tap((user: any) => {
          if (user) {
            this.fileForm.get('toEmployeeId')?.patchValue(user.id);
          }
        })
      )
      .subscribe(() => {
      }, () => {
      });


    this.documentService.documentsToSign$
      .pipe(
        take(1),
        tap((documents: DMSDocument[]) => {
          if (documents.length > 0) {


            for (let document of documents) {

              this.filesToUpload.push({
                name: this.documentService.getDocumentName(document, true),
                content: document.id,
                type: document.extension.toLowerCase()
              });
              this.fileForm.get('files')?.patchValue(this.filesToUpload);
            }

            this.activateSignMode(this.fileForm.get('files')?.value);
          }
        })
      )
      .subscribe(() => {
      }, () => {
      });

    this.uploadAvailable$ = this.requestManager.getForInstance('/dms/upload/available')
      .pipe(
        map((response: any) => response.available || false)
      );

    this.userClients$ = this.requestManager.getForInstance('user/self')
      .pipe(
        switchMap(userData => {
          if (userData.id) {
            return this.requestManager.getForInstance(`users/${userData.id}/userClients`)
              .pipe(
                map(userClients => {
                  const clients = [];
                  for (let userClient of userClients) {
                    if (userClient.client && Array.isArray(userClient.client) && userClient.client.length === 1) {
                      for (let client of userClient.client) {
                        clients.push(client);
                      }
                    }
                  }
                  return Object.values(clients);
                }),
                tap(clients => {
                  if (clients && Array.isArray(clients) && clients.length === 1) {
                    this.fileForm.get('fromClientId')?.patchValue(clients[0].id);
                  }
                }),
                catchError(error => {
                  this.notifier.notify('error', 'Es gab einen Fehler beim Laden der verfügbaren Mandanten!');
                  return throwError(error);
                })
              );
          }
          return of([]);
        })
      );
  }

  ngOnInit(): void {
    this.fileForm.get('fromClientId')!.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap(() => this.loadingEmployees$.next(true)),
        switchMap(clientId => this.loadEmployees(clientId)),
        tap((employees: Employee[]) => {

          if (employees && Array.isArray(employees)) {
            this.allEmployees = employees.filter(employee => employee.status !== 'inactive');
            this.filteredEmployees = [...this.allEmployees]; // Kopiere das gefilterte Array

            if (this.secretaryEmail) {
              const secretaryEmployee = {
                id: "secretary",
                display_name: this.companyName ?? "Sekretariat",
                status: "active"
              };

              // Füge den Sekretär an den Anfang des Arrays hinzu
              this.filteredEmployees.unshift(secretaryEmployee);

              if (this.fileForm.get('toEmployeeId')?.value === '' || this.fileForm.get('toEmployeeId')?.value == null) {
                this.fileForm.get('toEmployeeId')?.patchValue("secretary");
              }

            } else {
              if (this.allEmployees.length === 1) {
                if (this.fileForm.get('toEmployeeId')?.value === '') {
                  this.fileForm.get('toEmployeeId')?.patchValue(this.allEmployees[0].id);
                }
              }
            }
          }
          this.loadingEmployees$.next(false);
        }),
        catchError(error => {
          this.loadingEmployees$.next(false);
          this.notifier.notify('error', 'Es gab einen Fehler beim Laden der verfügbaren Mitarbeiter!');
          return throwError(error);
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    this.resetForm();
  }


  loadEmployees(clientId: string): Observable<Employee[]> {
    if (clientId) {
      return this.requestManager.getForInstance(`employees?clientId=${clientId}`);
    }
    return of([]);
  }

  filterEmployees(event: Event) {
    const input = event.target as HTMLInputElement;
    const searchTerm = input.value.toLowerCase();

    // Filtere die allEmployees Liste
    this.filteredEmployees = this.allEmployees.filter(employee =>
      employee.display_name.toLowerCase().includes(searchTerm)
    );

    // Überprüfen, ob "secretary" bereits in der gefilterten Liste ist
    const secretaryEmployee = {
      id: "secretary",
      display_name: this.companyName ?? "Sekretariat",
      status: "active"
    };

    const secretaryExists = this.filteredEmployees.some(employee => employee.id === 'secretary');

    // Füge "secretary" hinzu, falls er nicht bereits vorhanden ist
    if (!secretaryExists) {
      this.filteredEmployees.unshift(secretaryEmployee);
    }
  }


  submitNewFile() {
    this.loadingSubmit$.next(true);
    const body = this.fileForm.value;
    this.requestManager.post('dms/files/upload', body).subscribe(
      () => {
        this.loadingSubmit$.next(false);
        this.close.emit(true);
        this.deactivateSignMode();
        this.filesToUpload = [];
        this.notifier.notify('success', 'Dokument(e) erfolgreich hochgeladen!');

      },
      () => {
        this.notifier.notify('error', 'Es gab einen Fehler beim Hochladen der Dokumente! Melden Sie Ihr Problem bei support@riecken.io!');
        this.loadingSubmit$.next(false);
      }
    );
  }

  resetForm() {

    this.fileForm = this.fb.group({
      files: this.fb.control(''),
      fromClientId: this.fb.control('', [Validators.required]),
      toEmployeeId: this.fb.control('', [Validators.required]),
      subject: this.fb.control('', [Validators.required]),
      copyToMe: this.fb.control(false),
      filesToSign: this.fb.control(false, [Validators.required]),
      note: this.fb.control(''),
      answerRequested: this.fb.control(false)
    });
    this.signMode = false;
    this.filesToUpload = [];
    this.documentService.resetDocuments();
    this.documentService.resetEmployeeNumber();
    this.documentService.resetClientNumber();
    this.filteredEmployees = this.allEmployees;
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      Array.from(input.files).forEach(file => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const fileBase64Encoded = (reader.result as string).split(';base64,', 2)[1];

          // Datei-Erweiterung herausfinden
          const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'unknown';

          this.filesToUpload.push({
            name: file.name,
            content: fileBase64Encoded,
            type: fileExtension.toLowerCase() // Speichert die Erweiterung
          });
          this.fileForm.get('files')?.patchValue(this.filesToUpload);
        };
      });
    }
  }


  deleteFromUploadList(file: { name: string; content: string; type: string }) {
    const index = this.filesToUpload.indexOf(file);
    if (index !== -1) {
      this.filesToUpload.splice(index, 1);
      this.fileForm.get('files')?.patchValue(this.filesToUpload);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (!this.signMode) {
      this.isDragging = false; // Drag-Zustand zurücksetzen
      if (event.dataTransfer?.files) {
        const files = Array.from(event.dataTransfer.files);
        files.forEach(file => this.processFile(file));
      }
    }

  }

  // Drag-Bereich betreten
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true; // Zeigt an, dass Dateien gezogen werden
  }

  // Drag-Bereich verlassen
  onDragLeave(event: DragEvent) {
    this.isDragging = false; // Visuelles Feedback entfernen
  }

  // Über Drag-Bereich bewegen
  onDragOver(event: DragEvent) {
    event.preventDefault(); // Erlaubt das Ablegen von Dateien
  }

  // Datei verarbeiten
  processFile(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const fileBase64Encoded = (reader.result as string).split(';base64,', 2)[1];

      const fileExtension = file.name.split('.').pop()?.toLowerCase() || 'unknown';

      this.filesToUpload.push({name: file.name, content: fileBase64Encoded, type: fileExtension});
      this.fileForm.get('files')?.patchValue(this.filesToUpload);
    };
  }

  syncCheckboxes() {
    const answerRequestedValue = this.fileForm.get('answerRequested')!.value;
    this.fileForm.get('answerRequested')?.setValue(answerRequestedValue, {emitEvent: false});
  }

  cancelUpload() {
    this.resetForm();
    this.close.emit(true);
  }

  changeModeFiles(mode: 'SHOW' | 'HIDE') {
    this.modeFiles = mode;
  }

  toggleCopyToMe() {
    if (this.signMode) {
      return;
    }
    const currentValue = this.fileForm.get('copyToMe')?.value;
    this.fileForm.get('copyToMe')?.setValue(!currentValue);
  }

  private activateSignMode(files: { name: string, content: string, type: string }[]) {
    this.signMode = true;

    let textNote = "Hiermit bestätige ich das Dokument im Anhang";
    let textSubject = "Bestätigung Dokument " + (files.length > 0 ? files[0].name.replace(" signiert", "") : '');
    if (files.length > 1) {
      textNote = "Hiermit bestätige ich die Dokumente im Anhang";
      textSubject += " u.a.";
    }
    this.fileForm.get('filesToSign')?.patchValue(true);
    this.fileForm.get('note')?.patchValue(textNote);
    this.fileForm.get('subject')?.patchValue(textSubject);
    this.fileForm.get('copyToMe')?.patchValue(true);

    this.fileForm.get('files')?.setValidators([Validators.required]);
    this.fileForm.get('files')?.updateValueAndValidity();
  }

  private deactivateSignMode() {
    this.signMode = false;

    this.fileForm.get('files')?.clearValidators();
    this.fileForm.get('files')?.updateValueAndValidity();
  }


}
