<div class="header__background">
  <mat-toolbar role="toolbar" class="task-header">
    <h1 mat-dialog-title>
      {{'Download Dokumente'}}</h1>
    <span class="fx-spacer"></span>
    <button
      (click)="clickCancelButton()"
      *ngIf="showCancelButton"
      mat-icon-button>
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <p>Mit diesem Dokument sind weitere Dokumente verknüpft, diese werden ebenfalls
    heruntergeladen.</p>
</div>
<div class="footer" mat-dialog-actions align="end">
  <button
    *ngIf="showCancelButton"
    mat-button (click)="clickCancelButton()">{{'Abbrechen'}}
  </button>
  <button
    mat-flat-button color="primary"
    (click)="clickSaveButton()"
    class="button-primary">
    {{'OK'}}
  </button>
</div>
