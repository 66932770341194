import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../services/request-manager.service";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take, tap,
  throwError
} from "rxjs";
import {NotifierService} from "angular-notifier";
import {DocumentFilterService} from "../../services/document-filter.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.css']
})
export class OrdersPageComponent implements OnInit {

  filterCompletionStatus$ = new BehaviorSubject<any>([]);

  defaultOptionsCompletionStatus = {
    started: {description: 'Gestartet', value: 'started', checked: true},
    created: {description: 'Erstellt', value: 'created/planned', checked: true},
    interrupted: {description: 'Unterbrochen', value: 'interrupted', checked: true},
    done: {description: 'Erledigt', value: 'done', checked: false},
    workCompleted: {description: 'Abgeschlossen', value: 'work completed', checked: false},
    wortPartiallyCompleted: {description: 'Teilweise abgeschlossen', value: 'work partially completed', checked: true}
  };

  filterBillingStatus$ = new BehaviorSubject<any>([]);


  defaultOptionsBillingStatus = {
    invoiced: {description: 'Abgerechnet', value: 'invoiced', checked: false},
    partiallyInvoiced: {description: 'Teilweise abgerechnet', value: 'partial', checked: true},
    open: {description: 'Offen', value: 'open', checked: true},
  };

  optionsCompletionStatus: any;
  optionsBillingStatus: any;

  orders$: Observable<any>;
  loading$ = new BehaviorSubject(false);

  showAmountsInOrderTab = false;

  cachedTurnovers: Map<any, any> = new Map();

  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private filterService: DocumentFilterService,
              private router: Router) {
    this.optionsCompletionStatus = JSON.parse(JSON.stringify(this.defaultOptionsCompletionStatus));
    this.optionsBillingStatus = JSON.parse(JSON.stringify(this.defaultOptionsBillingStatus));

    this.requestManager.get('general/settings/')
      .pipe(
      ).subscribe((settings) => {
      this.showAmountsInOrderTab = settings.showAmountsInOrderTab === '1';
    });

    this.orders$ =
      combineLatest(
        [
          this.filterCompletionStatus$,
          this.filterBillingStatus$
        ]).pipe(
        switchMap(([filtersCompletionStatus, filterBillingStatus]) => {
          this.loading$.next(true);
          return this.requestManager.getForInstance('orders?filter=' + (filtersCompletionStatus.length > 0 || filterBillingStatus.loading$ > 0)
            + (filtersCompletionStatus.length > 0 ? '&filterCompletionStatus=' + filtersCompletionStatus : '')
            + (filterBillingStatus.length > 0 ? '&filterBillingStatus=' + filterBillingStatus : '')).pipe(
            switchMap((orders) => {

              if (orders.error_description != undefined) {
                this.loading$.next(false);
                this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Aufträge! Melden Sie Ihr Problem bei support@riecken.io!');
                return [];
              }
              const requests = [];

              for (let order of orders) {
                /*
                 * dont display ordertype 2101
                 */
                if (order.ordertype !== '2101') {

                  requests.push(this.getClientForId(order.client_id));
                }

              }
            if (requests.length === 0) {

              return of([]);
            }

            return forkJoin(requests).pipe(
              catchError((error) => {
                console.log(error);
                this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Aufträge! Melden Sie Ihr Problem bei support@riecken.io!');
                this.loading$.next(false);
                return of(orders ?? []);
              }),
              map((clients) => {
                  const matchingOrders = [];
                  for (let order of orders) {
                    /*
                   * dont display ordertype 2101
                   */
                    if (order.ordertype !== '2101') {

                      const matchingClient = clients.find((client: any) => order.client_id == client.datevClientId);
                      if (matchingClient != undefined) {
                        order.client = matchingClient.name;
                      }

                      if (order) {
                        matchingOrders.push(order);
                      }
                    }
                  }
                  return matchingOrders;
                },
                catchError((error) => {
                  console.log(error);
                  this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Aufträge! Melden Sie Ihr Problem bei support@riecken.io!');

                  return of(orders ?? []);
                }))
            );
            }),
            catchError((error) => {
              console.log(error);
              this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Aufträge! Melden Sie Ihr Problem bei support@riecken.io!');
              return of([]);
            })
          );
        }),
        switchMap((orders) => {
          this.cachedTurnovers.clear();
          const requests: any[] = [];

          if (orders.length === 0) {
            return of([]);
          }

          orders.forEach((order: any) => {
            requests.push(this.getActualTurnover(order).pipe(
              take(1)
            ));
          })
          return forkJoin(requests)
            .pipe(
              map(() => {
                return orders;
              })
            );
        }),
        tap(() => {
          this.loading$.next(false);
        }),
        catchError((error) => {
          console.log(error);
          this.loading$.next(false);
          this.notifier.notify('error', 'Es gab einen Fehler beim Laden der Aufträge! Melden Sie Ihr Problem bei support@riecken.io!');
          return of([]);
        })
      )
  }

  ngOnInit(): void {
    this.applyFilter();
  }

  setFilterAndDisplayDocuments(order: any) {
    this.filterService.setFilter('0', 'all');
    this.router.navigateByUrl('documents');
  }

  resetFilter() {
    this.optionsCompletionStatus = JSON.parse(JSON.stringify(this.defaultOptionsCompletionStatus));
    this.optionsBillingStatus = JSON.parse(JSON.stringify(this.defaultOptionsBillingStatus));
    this.applyFilter();
  }

  applyFilter() {
    const checkedCompletionStatus: string[] = [];
    Object.values(this.optionsCompletionStatus).forEach((completionStatus: any) => {
      if (completionStatus.checked) {
        checkedCompletionStatus.push(completionStatus.value);
      }
    })
    this.filterCompletionStatus$.next(checkedCompletionStatus);

    const checkedBillingStatus: string[] = [];
    Object.values(this.optionsBillingStatus).forEach((billingStatus: any) => {
      if (billingStatus.checked) {
        checkedBillingStatus.push(billingStatus.value);
      }
    })
    this.filterBillingStatus$.next(checkedBillingStatus);
  }


  getClientForId(client_id: any): Observable<any> {
    return this.requestManager.getForInstance('clients/' + client_id).pipe(
      map((client) => {
        return client;
      })
    );
  }

  getBillingStatus(billingStatus: any): string {
    let billingStatusAdapted = billingStatus;

    if (billingStatus === 'partially invoiced'
      || billingStatus === 'advance payment partially invoiced'
      || billingStatus === 'advance invoiced') {
      return 'Teilweise abgerechnet';
    }

    const matchingBillingStatus: any = Object.values(this.optionsBillingStatus)
      .find((optionBillingStatus: any) => optionBillingStatus.value === billingStatusAdapted);

    if (matchingBillingStatus) {
      return matchingBillingStatus.description;
    }

    return '-';
  }

  getCompletionStatus(completion_status: any) {
    const matchingCompletionStatus: any = Object.values(this.optionsCompletionStatus)
      .find((optionCompletionStatus: any) => optionCompletionStatus.value === completion_status);

    if (matchingCompletionStatus) {
      return matchingCompletionStatus.description;
    }

    return '-';
  }

  getActualTurnover(order: any): Observable<any> {


    if (this.cachedTurnovers.has(order.id)) {
      return of(this.cachedTurnovers.get(order.id));
    }

    return this.requestManager.get('orders/' + order.id + '/turnover')
      .pipe(
        tap((orderTurnovers) => {
          if (Array.isArray(orderTurnovers) && orderTurnovers.length > 0) {
            let turnover = 0;
            for (const orderTurnover of orderTurnovers) {
              turnover += orderTurnover.total_turnover;
              this.cachedTurnovers.set(order.id, turnover);
            }
          } else {
            this.cachedTurnovers.set(order.id, '-');
          }

        })
      );
  }
}


