import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {RequestManagerService} from "../../../../services/request-manager.service";
import {BehaviorSubject, combineLatest, debounceTime, map, Observable, of, switchMap, take, tap} from "rxjs";
import {NotifierService} from "angular-notifier";

interface DialogData {
  userId: number;
}

@Component({
  selector: 'app-user-clients-modal',
  templateUrl: './user-clients-modal.component.html',
  styleUrls: ['./user-clients-modal.component.scss']
})
export class UserClientsModalComponent implements OnInit {

  loading$ = new BehaviorSubject(false);
  userId: number | null = null;
  userClients$: Observable<any[]>;
  addableClients$: Observable<any[]>;
  allAddableClients: any[] = [];
  filteredAddableClients: any[] = [];
  reload$ = new BehaviorSubject(false);

  addNewClientForm = this.fb.group({
    clientId: this.fb.control('', [Validators.required]),
    clientsSearch: this.fb.control('')
  });


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              private dialogRef: MatDialogRef<UserClientsModalComponent>,
              private fb: FormBuilder,
              private requestManager: RequestManagerService,
              private notifier: NotifierService,) {
    this.dialogRef.disableClose = true;
    this.userId = data.userId;

    this.userClients$ = combineLatest([this.reload$]).pipe(
      switchMap(([reload]: any) => {
        // console.log(reload);
        if (reload && this.userId) {
          return this.requestManager.getForInstance('users/' + this.userId + '/userClients').pipe(
            map((userClients: any) => {

              if (userClients && Array.isArray(userClients)) {
                const clients = [];
                for (const userClient of userClients) {
                  // console.log(userClient);
                  if (userClient.client && Array.isArray(userClient.client)) {
                    for (const client of userClient.client) {
                      clients.push(client);
                    }
                  }
                }
                return clients;
              }
              return [];
            })
          );
        }
        return of([]);
      }));

    this.addableClients$ = combineLatest([this.reload$]).pipe(
      switchMap(([reload]: any) => {
        if (reload && this.userId) {
          return this.requestManager.getForInstance('/user/' + this.userId + '/clients/notAssigned')
            .pipe(
              map((clients) => {
                this.allAddableClients = clients;
                this.filteredAddableClients = clients;

                return this.filteredAddableClients;
              })
            );
        }
        return of([]);
      }));

    this.addNewClientForm.get('clientsSearch')?.valueChanges.pipe(
      debounceTime(300),
      tap((filterText: string) => {
        this.filterAddableClients(filterText);
      }))
      .subscribe();
  }

  ngOnInit(): void {
    this.reload$.next(true);
  }


  clickClose() {
    this.dialogRef.close();
  }

  addClientToUser() {
    this.loading$.next(true);

    const clientId = this.addNewClientForm.get('clientId')?.value;
    if (this.userId && clientId) {
      this.requestManager.post('users/' + this.userId + '/client/' + clientId + '/add', {})
        .pipe(
          take(1)
        )
        .subscribe(
          (success) => {
            console.log(success);
            this.reload$.next(true);
            this.addNewClientForm.reset();
            this.loading$.next(false);
          },
          (error) => {
            console.log(error);
            if (error.status != undefined && error.status != null && error.status === 409) {
              this.notifier.notify('error', 'Diese Verbindung von Benutzer und Mandant existiert schon!');
            } else {
              this.notifier.notify('error', 'Es gab einen Fehler beim Hinzufügen des Mandanten! Melden Sie Ihr Problem bei support@riecken.io!');
            }
            this.loading$.next(false);
          }
        )
    }

  }

  deleteUserClient(client: any) {
    this.loading$.next(true);

    if (this.userId && client) {
      this.requestManager.delete('users/' + this.userId + '/client/' + client.id + '/delete')
        .pipe(
          take(1)
        )
        .subscribe(
          (success) => {
            console.log(success);
            this.reload$.next(true);
            this.addNewClientForm.reset();
            this.loading$.next(false);
          },
          (error) => {
            console.log(error);

            this.notifier.notify('error', 'Es gab einen Fehler beim Löschen des Mandanten! Melden Sie Ihr Problem bei support@riecken.io!');

            this.loading$.next(false);
          }
        )
    }
  }

  private filterAddableClients(filterText: string) {
    if (filterText === '' || filterText === null) {
      this.filteredAddableClients = this.allAddableClients;
    } else {
      this.filteredAddableClients = this.allAddableClients.filter(
        (client) => {
          return client.name.toString().toLowerCase().includes(filterText.toLowerCase())
            || client.clientNumber.toString().includes(filterText.toLowerCase());
        }
      );
    }
  }
}
