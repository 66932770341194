import { InjectionToken } from '@angular/core';


export interface RequestEnvironment {
  baseURL: string,
  authURL: string
}

/**
 * @const ENVIRONMENT
 * Injection token for the environment interface to be provided by the applications.
 */
export const REQUEST_ENVIRONMENT: InjectionToken<RequestEnvironment> = new InjectionToken('REQUST_ENVIRONMENT');
