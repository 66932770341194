import {Component, OnInit} from '@angular/core';
import {LoginService} from "../../services/login.service";
import {Observable, of, take, tap} from "rxjs";
import {SessionManagerService} from "../../services/session-manager.service";
import {RequestManagerService} from "../../services/request-manager.service";
import {User} from "../../services/data-interfaces";
import {Router} from "@angular/router";
import {DocumentPageService} from "../../services/document-page.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLoggedIn$: Observable<boolean> = of(false);
  userData: User | null = null;
  tabOrderActive = false;
  dataProtectionLink: string | null = null;
  imprintLink: string | null = null;
  countNotDownloadedDocuments: number | null = null;
  contactLink: string | null = null;
  companyName: string | null = null;
  logo: SafeUrl | null = null;

  constructor(private loginService: LoginService,
              private sessionManager: SessionManagerService,
              private requestManager: RequestManagerService,
              private router: Router,
              private documentsPageService: DocumentPageService,
              private sanitizer: DomSanitizer) {

    this.isLoggedIn$ = this.loginService.getIsLoggedIn().pipe(tap((result) => {
      const userData = localStorage.getItem('user');

      this.requestManager.get('general/settings/')
        .pipe(
        ).subscribe((settings) => {
        this.tabOrderActive = settings.tabOrderActive === '1';
        this.dataProtectionLink = settings.dataProtectionLink;
        this.imprintLink = settings.imprintLink;
        this.contactLink = settings.contactLink;
        this.companyName = settings.companyName;
        this.logo = this.sanitizer.bypassSecurityTrustUrl(settings.logoSrc);

      });

      if (result) {
        this.getDocumentsNotDownloadedCount()
          .subscribe();

      }

      if (userData) {
        this.userData = JSON.parse(userData);
      }
    }));

    this.documentsPageService.getDocumentsDownloaded()
      .subscribe((result) => {
        if (result) {
          this.getDocumentsNotDownloadedCount()
            .pipe(take(1))
            .subscribe();
        }
      })

  }

  getDocumentsNotDownloadedCount(): Observable<any> {
    return this.requestManager.get('/outboxes/notDownloaded/count')
      .pipe(
        tap((result) => {
          if (result['count'] !== undefined && result['count'] !== null) {
            this.countNotDownloadedDocuments = result['count'];
          }
        })
      );
  }

  ngOnInit(): void {
  }

  logout() {

    this.sessionManager.logout();
    this.loginService.setIsLoggedIn(false);

  }


  routeToStartPage() {
    this.router.navigateByUrl('/documents');
  }

  isStartPageActive(): boolean {
    return this.router.isActive('documents', true);
  }

  openLink(link: string | null) {
    console.log(link);
    if (link) {
      window.open(link, '_blank');
    }
  }
}
