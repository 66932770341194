<mat-card>
  <mat-card-header>
    <mat-card-title>
      Upload von Dokumenten
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="form" [formGroup]="dmsSettingsForm">

      <mat-form-field appearance="fill" *ngIf="(selectableUsers$ |async) as selectableUsers"
      >
        <mat-label>Hochladender Benutzer in DATEV</mat-label>
        <mat-select formControlName="userId">
          <mat-option *ngFor="let selectableUser of selectableUsers" value="{{selectableUser.id}}">
            {{selectableUser.display_name ?? 'Unbekannt'}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="dmsSettingsForm.get('userId')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="(selectableDomains$ |async) as selectableDomains">
        <mat-label>Registerauswahl</mat-label>
        <mat-select formControlName="domainSelect">
          <mat-option>
            <ngx-mat-select-search
              formControlName="domainSearch"
              placeholderLabel="Suchbegriff eingeben"
            ></ngx-mat-select-search>
          </mat-option>
          <ng-container>
            <ng-container *ngFor="let domain of filteredSelectableDomains | keyvalue">
              <mat-option value="{{domain.key}}">
                {{domain.value}}
              </mat-option>
            </ng-container>
          </ng-container>


        </mat-select>
        <mat-error *ngIf="dmsSettingsForm.get('domainSelect')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="fill" *ngIf="dmsFeatureEnabled && (documentStates$ |async) as documentStates"
      >
        <mat-label>Dokumenten-Status beim Upload</mat-label>
        <mat-select formControlName="documentStatus">
          <mat-option *ngFor="let state of documentStates" value="{{state.id}}">
            {{state.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="dmsSettingsForm.get('documentStatus')?.hasError('required')">
          Muss ausgefüllt werden
        </mat-error>
      </mat-form-field>
    </div>

  </mat-card-content>
  <mat-card-footer>
    <button mat-raised-button
            color="primary"
            class="btn-save-settings"
            (click)="submitDmsSettingsForm()"
            [disabled]="dmsSettingsForm.invalid || (loadingSubmitDMSSettingsForm$ | async) === true">
      DMS Einstellungen speichern
    </button>
    <mat-spinner class="spinner-loading-add-client"
                 *ngIf="(loadingSubmitDMSSettingsForm$ | async) === true"
                 [diameter]="25">
    </mat-spinner>
  </mat-card-footer>

</mat-card>



