import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {downloadFilterStatus} from "./data-interfaces";

export interface orderItem {
  clientId: string,
  orderNumber: number
}

@Injectable({
  providedIn: 'root'
})
export class DocumentFilterService {


  private downloadedFilter$ = new BehaviorSubject<downloadFilterStatus>('all');
  private taskUndoneFilter$ = new BehaviorSubject<'1' | '0'>('0');

  constructor() {
  }


  getDownloadedFilter(): Observable<downloadFilterStatus> {
    return this.downloadedFilter$;
  }

  getTaskUndoneFilter(): Observable<'1' | '0'> {
    return this.taskUndoneFilter$;
  }

  setDownloadedFilter(downloaded: downloadFilterStatus): void {
    this.downloadedFilter$.next(downloaded);
  }

  setTaskUndoneFilter(taskUndone: '1' | '0'): void {
    this.taskUndoneFilter$.next(taskUndone);
  }

  setFilter(taskUndoneFilterStatus: '1' | '0', downloaded: downloadFilterStatus) {
    this.setTaskUndoneFilter(taskUndoneFilterStatus);
    this.setDownloadedFilter(downloaded);
  }

}
