import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Observable, of, switchMap, tap} from "rxjs";
import {RequestManagerService} from "./request-manager.service";
import {SessionManagerService} from "./session-manager.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isLoggedIn$ = new BehaviorSubject(false);

  constructor(private requestManager: RequestManagerService,
              private sessionManager: SessionManagerService) {
    this.isLoggedIn$.next(false);
  }

  setIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn$.next(isLoggedIn);
  }

  getIsLoggedIn(): Observable<boolean> {
    return this.isLoggedIn$.pipe(
      switchMap((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          return of(isLoggedIn);
        }

        if (localStorage.getItem('isLoggedIn')
          && localStorage.getItem('isLoggedIn') === '1'
          && localStorage.getItem('accessToken')) {

          return this.requestManager.get('user/checkJWT', {jwt: localStorage.getItem('accessToken')}).pipe(
            map((result) => {
              return result['jwt'] !== undefined && result['jwt'] === 'valid';
            }),
            catchError((error) => {
              console.log(error);
              this.sessionManager.logout();
              return of(false);
            })
          )
        }
        return of(false);
      })
    );
  }
}
