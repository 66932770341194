<div class="header__background">
  <mat-toolbar role="toolbar" class="task-header">
    <h1 mat-dialog-title>{{'Benutzer Löschen'}}</h1>
    <span class="fx-spacer"></span>
    <button
      (click)="clickCancelButton()"
      mat-icon-button>
      <mat-icon mat-list-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<div mat-dialog-content>
  <p>Möchten Sie den Benutzer mit der E-Mail {{data.email.toLowerCase()}} löschen?</p>
</div>
<div class="footer" mat-dialog-actions align="end">
  <button
    mat-button (click)="clickCancelButton()">{{'Abbrechen'}}
  </button>
  <button
    mat-raised-button color="warn"
    (click)="clickSaveButton()">
    {{'Bestätigen'}}
  </button>
</div>
