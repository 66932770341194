<div class="container-form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h2>Die angegebene Seite existiert nicht oder konnte nicht gefunden werden</h2>
    </mat-card-content>
    <mat-card-footer class="mat-card-footer-submit">
    </mat-card-footer>
  </mat-card>
</div>

