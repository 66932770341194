<mat-card>

  <mat-card-header>
    <mat-card-title>
      Benutzer Freigabe (Neueste zuerst)
    </mat-card-title>
    <mat-card-subtitle>
      Diese {{numberOfUserToApprove}} Mandanten haben Postausgänge in DATEV, aber keine Benutzer in meineKanzlei.
      <br/>
      Prüfen Sie die Daten (Herkunft = DATEV) vor Freigabe. Änderungen am Namen werden <b>nicht</b> nach DATEV
      zurückgeschrieben.
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div class="block-spinner">
      <ng-container *ngIf="(loading$ | async) === true">
        <mat-spinner [diameter]="35" class="loading-spinner"></mat-spinner>
        <!--        <mat-progress-bar mode="determinate" [value]="loadingProgress"></mat-progress-bar>-->
        <!--        <div class="loading-text">-->
        <!--          {{loadingProgress | number: '1.0-0'}} % wurden geladen-->
        <!--        </div>-->
      </ng-container>

    </div>
    <ng-container *ngIf="(clientUserApprovals$ | async) as clientUserApprovals; else noUserForApproval">

      <ng-container *ngIf="clientUserApprovals.length > 0">
        <div class="table-head mainGrid">
          <div class="table-head">
            <mat-card-subtitle>
              Erstellt am
            </mat-card-subtitle>
          </div>
          <div class="table-head">
            <mat-card-subtitle>
              Mandant
            </mat-card-subtitle>
          </div>

          <div
            class="table-head">
            <mat-card-subtitle>
              Mandantenname
            </mat-card-subtitle>
          </div>

          <div class="table-head">
            <mat-card-subtitle>
              Standard E-Mail
            </mat-card-subtitle>
          </div>
          <div class="table-head">
            <mat-card-subtitle>
              Vorname
            </mat-card-subtitle>
          </div>
          <div class="table-head">
            <mat-card-subtitle>
              Nachname
            </mat-card-subtitle>
          </div>
          <div class="table-head">
            <mat-card-subtitle>
              Aktion
            </mat-card-subtitle>
          </div>
        </div>
        <div *ngFor="let clientUserApproval of clientUserApprovals" class="table-row mainGrid">
          <mat-divider></mat-divider>

          <div class="table-row-align-middle">
            {{clientUserApproval.entryCreated  | date: 'dd.MM.yyyy'}}
          </div>
          <div class="table-row-align-middle">
            {{clientUserApproval.clientNumber }}
          </div>
          <div class="table-row-align-middle">
            {{clientUserApproval.name }}
          </div>

          <div class="table-row-align-middle">
            <mat-form-field appearance="fill" class="form-field-email">
              <mat-label>E-Mail</mat-label>
              <input
                required
                matInput
                [disabled]="true"
                [(ngModel)]="clientUserApproval.email"
                #emailInput="ngModel"/>
            </mat-form-field>
            <mat-error *ngIf="emailInput.invalid">
              Bitte geben Sie eine gültige E-Mail-Adresse ein.
            </mat-error>
          </div>
          <div class="table-row-align-middle">
            <mat-form-field appearance="fill" class="form-field-email">
              <mat-label>Vorname</mat-label>
              <input
                [disabled]="(loading$ | async) === true"
                required
                matInput
                [(ngModel)]="clientUserApproval.firstname"/>
            </mat-form-field>
          </div>
          <div class="table-row-align-middle">
            <mat-form-field appearance="fill" class="form-field-email">
              <mat-label>Nachname</mat-label>
              <input
                required
                matInput
                [disabled]="(loading$ | async) === true"
                [(ngModel)]="clientUserApproval.surname"/>
            </mat-form-field>
          </div>
          <div class="block-table-colum-action">
            <div class="  action-buttons">
              <button mat-raised-button
                      class="button-approve button-action"
                      color="primary"
                      [disabled]="!clientUserApproval.email
                      || !clientUserApproval.firstname
                      || !clientUserApproval.surname
                      || !isEmailValid(clientUserApproval.email)
                      || (loading$ | async) === true
                      || clientUserApproval.loading"
                      (click)="approveClientUserApproval(clientUserApproval)">Freigeben
              </button>
              <button mat-raised-button
                      class="button-action secondary-background-color"
                      matTooltip="E-Mail, Vorname und Nachname neu aus DATEV einlesen"
                      matTooltipPosition="right"
                      [disabled]="(loading$ | async) === true
                                  ||clientUserApproval.loading"
                      (click)="updateClientUserApproval(clientUserApproval)">Daten aktualisieren
              </button>
              <button mat-raised-button
                      class="button-action"
                      color="warn"
                      [disabled]="(loading$ | async) === true
                                ||clientUserApproval.loading"
                      (click)="deleteClientUserApproval(clientUserApproval)">Löschen
              </button>
            </div>
            <div class="action-buttons">
              <mat-spinner [diameter]="35" *ngIf="clientUserApproval.loading"></mat-spinner>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #noUserForApproval>
      <div class="mainGrid block-no-clientUserApprovals" *ngIf="(loading$ | async) === false">
        Keine Freigaben zu tätigen
      </div>
    </ng-template>
  </mat-card-content>

  <mat-card-footer>

  </mat-card-footer>
</mat-card>
