import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {BehaviorSubject, catchError, Observable, of, Subject, switchMap, take} from "rxjs";
import {ClientUserApproval} from "../../services/data-interfaces";
import {MatDialog} from "@angular/material/dialog";
import {ModalConfirmDeleteComponent} from "./modal-confirm-delete/modal-confirm-delete.component";

@Component({
  selector: 'app-user-approval',
  templateUrl: './user-approval.component.html',
  styleUrls: ['./user-approval.component.scss']
})
export class UserApprovalComponent implements OnInit {


  loading$ = new Subject<boolean>();
  reload$ = new BehaviorSubject(false);
  clientUserApprovals$: Observable<ClientUserApproval[]> | null = null;
  loadingProgress = 0;
  numberOfUserToApprove = 0;

  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private matDialogService: MatDialog,) {


    this.clientUserApprovals$ = this.reload$
      .pipe(
        switchMap((reload) => {
          if (reload) {
            this.loading$.next(true);
            return this.requestManager.get('clientUserApproval/unapproved')
              .pipe(
                switchMap((clientUserApprovals: ClientUserApproval[]) => {
                  for (let clientUserApproval of clientUserApprovals) {
                    clientUserApproval.loading = false;
                  }
                  this.loadingProgress = 0;
                  const totalApprovals = clientUserApprovals.length;
                  let loadedApprovals = 0;
                  this.numberOfUserToApprove = totalApprovals;
                  this.loading$.next(false);
                  return of(clientUserApprovals);
                }),
                catchError((error) => {
                  console.log(error);
                  this.notifier.notify('error', 'Es gab einen Fehler beim Laden der zu freigebenden Benutzer!');
                  return of([]);
                })
              );
          }
          return of([]);
        })
      )
  }

  ngOnInit(): void {
    this.reload$.next(true);
  }

  approveClientUserApproval(clientUserApproval: ClientUserApproval) {

    clientUserApproval.loading = true;
    this.requestManager.post('clientUserApproval/' + clientUserApproval.id + '/approve',
      {
        email: clientUserApproval.email,
        firstname: clientUserApproval.firstname,
        surname: clientUserApproval.surname
      })
      .pipe(
        take(1)
      )
      .subscribe(() => {
          clientUserApproval.loading = false;
          this.reload$.next(true);
        },
        (error) => {
          clientUserApproval.loading = false;
          this.notifier.notify('error', 'Es gab einen Fehler beim Freigeben dieses Benutzers!');
          this.reload$.next(true);
        })
  }

  isEmailValid(email: string): boolean {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  }

  nameIsvalid(name: string | null): boolean {
    return !(name === null || name === undefined || name === '' || name.length < 2);
  }

  deleteClientUserApproval(clientUserApproval: ClientUserApproval) {

    const modalRef = this.matDialogService.open(ModalConfirmDeleteComponent, {
      data: {
        clientNumber: clientUserApproval.clientNumber
      }
    });

    modalRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          this.requestManager.put('clientUserApproval/' + clientUserApproval.id + '/decline', {})
            .pipe(
              take(1)
            )
            .subscribe(() => {
                this.reload$.next(true);
              },
              (error) => {
                this.notifier.notify('error', 'Es gab einen Fehler beim Löschen des Einrags in der Benutzer-Freigabeliste!');
                this.reload$.next(true);
              })
        }
      });

  }

  updateClientUserApproval(clientUserApproval: ClientUserApproval) {
    clientUserApproval.loading = true;
    this.requestManager.put('clientUserApproval/' + clientUserApproval.id + '/update',
      {
        email: clientUserApproval.email,
        firstname: clientUserApproval.firstname,
        surname: clientUserApproval.surname
      })
      .pipe(
        take(1)
      )
      .subscribe((updatedClientUserApproval: ClientUserApproval) => {
          clientUserApproval.email = updatedClientUserApproval.email;
          clientUserApproval.firstname = updatedClientUserApproval.firstname;
          clientUserApproval.surname = updatedClientUserApproval.surname;
          clientUserApproval.loading = false;
        },
        (error) => {
          clientUserApproval.loading = false;
          this.notifier.notify('error', 'Es gab einen Fehler beim Aktualisieren der Daten dieses Benutzers!');
        })
  }
}
