<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Aufträge
      </mat-card-title>
      <mat-icon class="icon-filter" [mat-menu-trigger-for]="filterMenu">filter_list</mat-icon>
      <mat-menu #filterMenu class="filterMenu">

        <div class="menu-content">

          <h3 (click)="$event.stopPropagation();">Filter nach Abrechnungsstatus</h3>


          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsBillingStatus.invoiced.checked">{{optionsBillingStatus.invoiced.description  }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsBillingStatus.partiallyInvoiced.checked">{{optionsBillingStatus.partiallyInvoiced.description }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsBillingStatus.open.checked">{{optionsBillingStatus.open.description }}</mat-checkbox>
          </div>

          <h3 (click)="$event.stopPropagation();">Filter nach Erledigungstatus</h3>


          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.started.checked">{{optionsCompletionStatus.started.description }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.created.checked">{{optionsCompletionStatus.created.description  }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.interrupted.checked">{{optionsCompletionStatus.interrupted.description  }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.done.checked">{{optionsCompletionStatus.done.description  }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.workCompleted.checked">{{optionsCompletionStatus.workCompleted.description }}</mat-checkbox>
          </div>
          <div class="filteListRow">
            <mat-checkbox (click)="$event.stopPropagation();"
                          [(ngModel)]="optionsCompletionStatus.wortPartiallyCompleted.checked">{{optionsCompletionStatus.wortPartiallyCompleted.description }}</mat-checkbox>
          </div>



          <div class="mat-menu-footer">
            <button mat-button color="primary" (click)="resetFilter()">Zurücksetzen</button>
            <button mat-raised-button color="primary" (click)="applyFilter()">Anwenden</button>
          </div>
        </div>

      </mat-menu>
    </mat-card-header>
    <mat-card-content>
      <div class="table-head"
           [ngClass]="{'orderTableGridAmounts':  showAmountsInOrderTab, 'orderTableGrid' : !showAmountsInOrderTab}">

        <div>
          <mat-card-subtitle class="table-head-title">
            <div>
              Auftragsart
            </div>

            <mat-icon>
              arrow_upward
            </mat-icon>
          </mat-card-subtitle>
        </div>

        <div class="">
          <mat-card-subtitle>
            Veranlagungsjahr
          </mat-card-subtitle>
        </div>
        <div>
          <mat-card-subtitle class="">
            Beschreibung
          </mat-card-subtitle>
        </div>
        <div class="">
          <mat-card-subtitle>
            Mandant
          </mat-card-subtitle>
        </div>
        <div class="">
          <mat-card-subtitle>
            Rechnung
          </mat-card-subtitle>
        </div>
        <div class="">
          <mat-card-subtitle>
            Status
          </mat-card-subtitle>
        </div>

        <ng-container *ngIf="showAmountsInOrderTab">
          <div class="table-row-cell-align-right">
            <mat-card-subtitle>
              Plan
            </mat-card-subtitle>
          </div>
          <div class="table-row-cell-align-right">
            <mat-card-subtitle>
              Ist
            </mat-card-subtitle>
          </div>
        </ng-container>
        <!--        <div class="table-row-cell-align-right">-->
        <!--          <mat-card-subtitle>-->
        <!--            Dokumente-->
        <!--          </mat-card-subtitle>-->
        <!--        </div>-->
      </div>
      <div class="block-spinner">
        <mat-spinner class="loading-spinner"
                     *ngIf="(loading$ | async) === true"
                     [diameter]="35">
        </mat-spinner>
      </div>
      <ng-container *ngIf="(orders$ | async) as orders">

        <div *ngFor="let order of orders"
             [ngClass]="{'orderTableGridAmounts':  showAmountsInOrderTab, 'orderTableGrid' : !showAmountsInOrderTab}"
             class="orderTableGridHover table-row"
             (click)="setFilterAndDisplayDocuments(order)">
          <mat-divider></mat-divider>
          <div class="table-row-align-middle">
            {{order.ordertype ?? '-'}}
          </div>
          <div class=" table-row-align-middle">
            {{order.assessment_year ?? '-'}}
          </div>
          <div class=" table-row-align-middle">
            {{order.order_name ?? '-'}}
          </div>
          <div class=" table-row-align-middle">
            {{order.client ?? '-'}}
          </div>
          <div class=" table-row-align-middle">
            {{getBillingStatus(order.billing_status)}}
          </div>
          <div class=" table-row-align-middle">
            {{getCompletionStatus(order.completion_status)}}
          </div>

          <ng-container *ngIf="showAmountsInOrderTab">
            <div class=" table-row-align-middle table-row-cell-align-right">
              {{(order.planned_turnover | currency: 'EUR') ?? '-'}}
            </div>
            <div class=" table-row-align-middle table-row-cell-align-right">
              <ng-container>
                <ng-container *ngIf=" cachedTurnovers.has(order.id); else noValue">
                  {{cachedTurnovers.get(order.id) !== '-' ? (cachedTurnovers.get(order.id) | currency: 'EUR') : '-'}}
                </ng-container>
              </ng-container>
              <ng-template #noValue>{{'-'}}</ng-template>
            </div>
          </ng-container>
          <!--          <div class="table-row-cell-align-right table-row-align-middle">-->
          <!--            <div>-->
          <!--              <mat-icon (click)="setFilterAndDisplayDocuments(order)" class="icon-chevron-documents">chevron_right-->
          <!--              </mat-icon>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div *ngIf="orders.length === 0"
             [ngClass]="{'block-no-ordersAmounts':  showAmountsInOrderTab, 'block-no-orders' : !showAmountsInOrderTab}">
          Keine Aufträge
        </div>
      </ng-container>

    </mat-card-content>
  </mat-card>
</div>
