import {Component, OnInit} from '@angular/core';
import {RequestManagerService} from "../../services/request-manager.service";
import {NotifierService} from "angular-notifier";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, Validators} from "@angular/forms";
import {BehaviorSubject, catchError, of, switchMap, take, tap} from "rxjs";

@Component({
  selector: 'app-password-assignment',
  templateUrl: './password-assignment.component.html',
  styleUrls: ['./password-assignment.component.css']
})
export class PasswordAssignmentComponent implements OnInit {

  userId: string | null = null;
  passwordToken: string | null = null;
  userClients: { name: string }[] | null = null;
  errorGetUser = false;

  passwordForm = this.fb.group({
    clientNumber: this.fb.control('', [Validators.required]),
    email: this.fb.control('', [Validators.required]),
    password: this.fb.control('', [Validators.required, Validators.minLength(8)]),
    passwordRepeat: this.fb.control('', [Validators.required, Validators.minLength(8)]),
  });

  passwordUpdated: boolean = false;
  submitLoading$ = new BehaviorSubject(false);

  baseUrl: string = '';
  frontendLoginUrl: string = '';


  constructor(private requestManager: RequestManagerService,
              private notifier: NotifierService,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
    this.route.params
      .pipe(
        tap((params) => {
          this.userId = params['userId'];
          this.passwordToken = params['passwordToken'];
        }),
        switchMap(() => {
          return this.requestManager.get('users/' + this.userId + '/passwordChangeToken/' + this.passwordToken + '/userClients')
            .pipe(
              tap((userData) => {

                if (userData['email']) {
                  this.passwordForm.patchValue({email: userData['email']})
                }
                if (userData['clients']) {
                  this.userClients = userData['clients'];
                }
              })
            )
        })
      )
      .subscribe(() => {
        },
        (error) => {
          this.errorGetUser = true;
        });

    this.baseUrl = window.location.toString()
      .replace("https://", "")
      .replace("http://", "")
      .split("/")[0];

    /*
     * for debugging
     */
    if (this.baseUrl.includes('localhost')) {
      this.baseUrl = 'hagena.st3uern.riecken.io';
    }

    console.log(this.baseUrl);

    this.requestManager.setBaseUrl(this.requestManager.getInstanceUrl(this.baseUrl));
  }

  ngOnInit(): void {
  }

  submit() {

    if (this.passwordForm.get('password')?.value !== this.passwordForm.get('passwordRepeat')?.value) {
      this.notifier.notify('error', 'Die eingegebenen Passwörter stimmen nicht überein!');
      return;
    }

    this.submitLoading$.next(true);

    if (this.userId) {

      this.requestManager.post('user/' + this.userId + '/password/' + this.passwordToken, this.passwordForm.value)
        .pipe(
          take(1),
          tap((response) => {
            this.passwordUpdated = true;
            this.submitLoading$.next(false);
            if (response['loginUrl'] !== undefined) {
              this.frontendLoginUrl = response['loginUrl'];
            }

          }),
          catchError((error) => {
            this.notifier.notify('error', 'Es gab einen Fehler beim Speichern des Passworts!');
            this.submitLoading$.next(false);
            return of(null);
          })
        ).subscribe();
    }

  }
}
