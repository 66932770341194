<div class="block-component-wrapper-no-outbox-selected" *ngIf="outbox === null">
  <div class="block-no-outbox-selected">
    <mat-icon id="mail-icon-no-outbox-selected">mail_outline</mat-icon>
    <span>Wählen Sie eine Nachricht aus</span>
  </div>
</div>
<div *ngIf="outbox" class="block-component-wrapper-outbox-selected">
  <div class="outbox-details-header-wrapper">
    <div class="outbox-details-headerline-action-buttons">
      <button mat-raised-button
              class="button-primary"
              color="primary"
              (click)="answerOutbox()">
        Antworten
      </button>
      <button *ngIf="!allDocumentsAreOnlyPhoneNotes()"
              mat-raised-button
              class="button-primary"
              color="primary"
              (click)="answerOutbox(true)">Signieren/Bestätigen
      </button>
    </div>
    <div class="outbox-details-headerline-pre-subject">
      <span>{{outbox.outboxDetails.commit_date | date:'dd.MM.yyyy - hh:mm'}}</span>
    </div>
    <div class="outbox-details-header">
      <span class="outbox-details-headerline-subject">{{outbox.outboxDetails.subject}}</span>
      <div class="outbox-details-sender-wrapper">
        <span><b>Von:</b></span>
        <div class="outbox-details-sender">
          <span *ngIf="this.displayCommittingEmployeeAsSender ==='1'">
            {{outbox.outboxDetails.committed_by_employee_name}}
          </span>
          <span *ngIf="this.displayCommittingEmployeeAsSender !=='1'">
            {{outbox.outboxDetails.creating_employee_name}}
          </span>
          <mat-icon>person</mat-icon>
        </div>
      </div>
    </div>
    <div class="outbox-details-header-files">
      <div class="outbox-details-header-file-details">
        {{outbox.documents.length}}
        <span *ngIf="outbox.documents.length === 1">&nbsp;{{"Anhang"}}</span>
        <span *ngIf="outbox.documents.length > 1">&nbsp;{{"Anhänge"}}</span>
        <mat-icon class="">attach_file</mat-icon>
      </div>
      <ng-container *ngIf="!downloadingAllDocuments; else spinnerTemplate">
        <mat-icon class="outbox-details-header-files-icon-download" (click)="downloadDocuments()">download</mat-icon>
      </ng-container>
      <ng-template #spinnerTemplate>
        <mat-spinner [diameter]="24"></mat-spinner>
      </ng-template>
    </div>
  </div>
  <div>

    <ng-container *ngFor="let document of outbox.documents">
      <ng-container *ngIf="checkAndLoadDocumentNote(document)">
        <mat-card class="mat-card-document">
          <ng-container *ngIf="documentIsPhoneNote(document) else documentIsNoPhoneNote">
            <div class="mat-card-document-flex-left">
              <div class="disabled"
                   matTooltip="Keine Datei zum Download verknüpft"
                   matTooltipPosition="right">
                <mat-icon class="disabled">description</mat-icon>
              </div>
              <div class="disabled"
                   matTooltip="Keine Datei zum Download verknüpft"
                   matTooltipPosition="right">
            <span class="document-title">
              {{ "zu: " + getDocumentTitle(document.description, document.extension) | truncate: 75 }}
            </span>
              </div>
            </div>
          </ng-container>

          <ng-template #documentIsNoPhoneNote>
            <div class="mat-card-document-flex-left cursor-pointer">
              <mat-icon>description</mat-icon>
              <span *ngIf="outbox.documents.length > 1" class="document-title">
            {{ "zu: " + getDocumentTitle(document.description, document.extension) | truncate: 75 }}
          </span>
            </div>
          </ng-template>

          <!-- Notiz anzeigen -->
          <div class="document-note" *ngIf="checkAndLoadDocumentNote(document)">
            <i>{{ document.note.text | removeBrackets }}</i>
          </div>

          <!-- Ladezustand für Notizen -->
          <div class="document-note-loading" *ngIf="loadingNotes[document.id]">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </mat-card>
      </ng-container>
    </ng-container>


  </div>
</div>
