import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'removeBrackets'
})
export class RemoveBracketsPipe implements PipeTransform {

  transform(value: string | undefined | null): string {
    // Regulären Ausdruck verwenden, um alles in eckigen Klammern zu entfernen
    if (value === undefined || value === null) {
      return "";
    }
    return value.replace(/\[.*?\]/g, '').trim();
  }

}
